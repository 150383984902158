@import "https://cdn.syncfusion.com/ej2/material.css";
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: unset !important;
  -ms-overflow-style: unset !important;
}

.w-13 {
  width: 13%;
}

.auto-dial-button.auto_disabled {
  background: #c7c9d9;
  cursor: not-allowed;
}

.remove_focus svg {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.call_icons .phone_active.auto_disabled {
  background: #c7c9d9;
  cursor: not-allowed;
}

.country_login .css-yk16xz-control {
  min-height: 49px;
}

.country_login .css-1pahdxg-control {
  min-height: 49px;
}
.conditions-section .multiselect-container .optionListContainer .selected {
  background-color: rgb(253 253 253);
  color: rgb(0 0 0);
}

.conditions-section .multiselect-container .optionListContainer {
  background: #fff;
}
.conditions-section .multiselect-container .chip {
  background: #5c90f7;
  border-radius: 2px;
}

.conditions-section .multiselect-container .highlightOption {
  background: #5c90f7;
}

.conditions-section .multiselect-container .search-wrapper {
  overflow-x: hidden;
}

.conditions-section .multiSelectContainer li:hover {
  background: #5c90f7;
}

@media (max-width: 700px) {
  .card-header-tabs .w-20 {
    width: 28% !important;
  }
}

.aliceBlue {
  background: aliceblue;
}

.custom_payment_transaction {
  max-height: 18rem;
  overflow-y: auto;
}

#content-variables {
  background: white;
  height: min-content;
  padding: 7px 10px;
  display: inline-block;
  border: 1px solid #eee;
  margin: 5px;
  margin-left: 0px;
}

#content-variables .variable-delete {
  margin-left: 12px;
  color: red;
  cursor: pointer;
  float: right;
}

.ui_offline {
  font-size: 14px;
  box-shadow: 0 1px 7px rgb(0 0 0 / 18%), 0 1px 3px rgb(0 0 0 / 15%);
  padding: 11px 16px;
  border: 1px solid #bbbbbb;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1050;
  background: #f6f6f6;
  color: #444444;
  margin: 0 32%;
  text-align: center;
}

.ui_online {
  font-size: 14px;
  box-shadow: 0 1px 7px rgb(0 0 0 / 18%), 0 1px 3px rgb(0 0 0 / 15%);
  padding: 11px 16px;
  border: 1px solid #bbbbbb;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1050;
  background: #f6f6f6;
  color: #444444;
  margin: 0 32%;
  text-align: center;
}

.ui_online .dot {
  margin-right: 8px;
  height: 12px;
  width: 12px;
  background-color: #80d580;
  border-radius: 50%;
  display: inline-block;
}

.ui_offline .dot {
  margin-right: 8px;
  height: 12px;
  width: 12px;
  background-color: #eb8787;
  border-radius: 50%;
  display: inline-block;
}

.modal-title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.tableFixHead {
  overflow-y: auto;
  max-height: 61vh;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.tableFixHead thead th:nth-child(even) {
  background: #f1f1f4;
  z-index: 4;
}

.tableFixHead .table-responsive {
  overflow-x: unset !important;
}

.skeleton-loader .skeleton {
  display: block;
  background: #fff;
  height: 23rem;
}
.skeleton-loader .skeleton.skeleton--card {
  width: 100%;
  display: inline-block;
  vertical-align: text-top;
}
.skeleton-loader .skeleton .skeleton--content {
  height: 150px;
  /* padding: 15px; */
  position: relative;
}
.skeleton-loader .skeleton .skeleton--content .loader {
  background: #f6f7f8;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--table .skeleton--tr {
  display: flex;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--table .skeleton--tr .skeleton--th {
  flex: 1 1 100%;
  height: 15px;
  margin: 5px 10px 15px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--table .skeleton--tr .skeleton--td {
  flex: 1 1 100%;
  height: 10px;
  margin: 5px 10px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--table .skeleton--tr .skeleton--td__2 {
  flex-basis: 300%;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--table .skeleton--tr .skeleton--td__3 {
  flex-basis: 500%;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--table .skeleton--tr .skeleton--td__4 {
  flex-basis: 700%;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--table .skeleton--tr .skeleton--td__5 {
  flex-basis: 900%;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--title {
  margin: 5px 10px;
  height: 20px;
  width: 200px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--hr {
  height: 2px;
  width: calc(100% - 20px);
  margin: 0 10px 10px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--line {
  height: 18px;
  width: calc(100% - 20px);
  margin: 15px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--line.skeleton--line__short {
  width: 120px;
}
.skeleton-loader .skeleton .skeleton--content .skeleton--circle {
  margin: 5px 10px 10px;
  height: 60px;
  width: 60px;
  border-radius: 10px;
}
.skeleton-loader .skeleton .skeleton--content .fl {
  display: inline-block;
  width: auto;
  vertical-align: text-top;
}
@keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media (min-width: 992px) {
  .custom_campaign_modal.modal-lg {
    max-width: 900px !important;
  }
}

.active_notice_id {
  font-weight: 900;
  color: #145388;
}

.whatsapp_icon {
  background: transparent;
  border: none;
  cursor: pointer;
}

.campaign_alert_box {
  width: 373px;
  height: 100px;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
  background: #ffffff;
  border-radius: 2px;
  padding: 9px;
}

.campaign_alert_box .attention {
  box-shadow: inset 0px -1px 0px #f0f0f0;
  height: 32px;
}

.campaign_alert_box .attention span {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ff5c5c;
}

.campaign_alert_box .subline {
  color: #8f90a6;
  font-size: 11px;
  line-height: 22px;
  height: 22px;
}

.campaign_alert_box .sub_subline {
  color: #ff5c5c;
  font-size: 11px;
  line-height: 22px;
  height: 22px;
}

.mt-7 {
  margin-top: 7rem !important;
}

.custom_input.form-control {
  background: #fafafc;
  padding: 0.5rem;
}

.content_variables #content-variables {
  border: 1px solid #d7d7d7;
  background: #e9ecef;
}

.floating_whatsapp {
  z-index: 11;
  position: fixed;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
}

.skeleton-loader.admin_portfolio_skeleton .skeleton {
  display: block;
  background: #fff;
  height: 34rem;
}

/* .portfolio_v2_table .agent_portfolio_table {
  table-layout: fixed;
}

.portfolio_v2_table .agent_portfolio_table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.portfolio_v2_table .agent_portfolio_table th {
  width: 100px;
}

.portfolio_v2_table .agent_portfolio_table th:nth-child(2) {
  width: 250px;
}

.portfolio_v2_table .agent_portfolio_table th:nth-child(3) {
  width: 250px;
}

.portfolio_v2_table .agent_portfolio_table th {
  width: 140px;
  font-weight: 550;
  font-size: 12.5px;
  vertical-align: middle !important;
}

.portfolio_v2_table .agent_portfolio_table th:first-child {
  width: 70px;
} */

.portfolio_v2_table .admin_portfolio_table th {
  width: 100px;
}

.portfolio_v2_table .admin_portfolio_table th:nth-child(3) {
  width: 50px;
}

.portfolio_v2_table th {
  width: 140px;
  font-weight: 550;
  font-size: 12.5px;
  vertical-align: middle !important;
}

.portfolio_v2_table th:first-child {
  width: 10px;
}

.portfolio_v2_table .table td {
  vertical-align: middle;
  border: none !important;
  padding: 11px 19px;
  font-size: 12.5px;
  border-color: #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.portfolio_v2_table .table .call_navigate_location td {
  border-bottom: none !important;
  font-size: 15px;
  padding: 0px 19px;
}

.portfolio_v2_table .call_navigate_location td:nth-child(even) {
  background: none !important;
}

.portfolio_v2_table .table th {
  border: none !important;
  border-color: #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.portfolio_v2_table th:nth-child(even) {
  background: #ebebf073;
}

.portfolio_v2_table td:nth-child(even) {
  background: #ebebf073;
}

.deposit-table th:nth-child(even),
.deposit-table td:nth-child(even) {
  background: none;
  border: none !important;
}

.deposit-table th:nth-child(odd),
.deposit-table td:nth-child(odd) {
  border: none !important;
}

.deposit-table tr:nth-child(odd) {
  background: #fafafc;
}

.portfolio_v2_table .deposit-table table tr:first-child {
  background-color: #eef4ff;
}

.portfolio_v2_table .table {
  text-align: center;
  border-radius: 1em;
  min-width: max-content;
}

.portfolio_last_column {
  position: sticky;
  right: 0;
  background: #eef3fd !important;
  z-index: 5 !important;
}

.portfolio_last_column_creditline {
  background: white;
  text-align: center;
  vertical-align: middle !important;
  position: sticky;
  right: 0;
  z-index: 1;
}

#panelContainer .jsPanel-depth-2 {
  z-index: 9999 !important;
  border-radius: 6px !important;
}

#panelContainer .jsPanel .jsPanel-hdr {
  border-radius: 6px;
  background: linear-gradient(147.14deg, #5b8def 6.95%, #5b8def 6.96%, #0063f7 93.05%);
  opacity: 0.8;
  color: rgb(255 255 255);
}

.ml-23rem {
  margin-left: 23rem;
}

.ml-30rem {
  margin-left: 30rem;
}

.call_icons_top {
  margin-bottom: 0px;
  display: inline-table;
  width: auto;
  font-size: 12px;
}

.call_status_red .form-control {
  border: 1px solid red;
}

.committed_amount_red .form-control {
  border: 1px solid red;
}

.redminder_date_red .react-datepicker__input-container input {
  border: 1px solid red !important;
}

#panelContainer .jsPanel-titlebar .jsPanel-title {
  color: white !important;
}

.iconsminds-stop-music.red {
  color: red;
}

.modal .modal-header.call_header_modal {
  padding: 1rem 1.75rem;
}

.call_icons_top tr:nth-child(even) td {
  padding: 4px;
}

.call_icons_top tr td {
  padding: 7px 38px;
}

#jsPanel-replacement-container {
  left: auto !important;
  right: 9px;
}

#jsPanel-replacement-container .jsPanel-replacement {
  background: linear-gradient(147.14deg, #5b8def 6.95%, #5b8def 6.96%, #0063f7 93.05%) !important;
  opacity: 0.8;
}

#panelContainer .jsPanel-icon {
  color: rgb(255 255 255) !important;
}

.btn-green {
  background-color: #39d98a;
  border-color: #39d98a;
  color: #fff;
}

.btn-secondary.btn-blue {
  box-shadow: 0px 0px 1px rgb(40 41 61 / 4%), 0px 2px 4px rgb(96 97 112 / 16%) !important;
  border-color: #3e7bfa;
  background: #3e7bfa;
  border-radius: 8px;
  padding: 6px 9px;
}

.btn-cancel {
  margin-bottom: 20px;
  padding: 6px 14px;
  border-radius: 4px;
  border: 1px solid #145388;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.btn-wrapper {
  display: flex;
  gap: 1rem;
  width: 100%;
  height: fit-content;
  justify-content: flex-end;
}

.live_voip_cards {
  background: #fafafc;
  text-align: center;
  box-shadow: 0px 0px 2px rgb(40 41 61 / 4%), 0px 4px 8px rgb(96 97 112 / 16%);
  border-radius: 10px;
  padding: 14px 9px 14px 9px;
}

.live_voip_cards .upper_text {
  font-size: 22px;
  color: #555770;
  font-weight: 700;
}

.live_voip_cards .bottom_text {
  font-size: 13px;
  color: #555770;
}

.team_status thead {
  background: #eef4ff;
}

.table.team_status thead th {
  border: none;
}
.did_show_hide {
  color: cornflowerblue;
  cursor: pointer;
  float: right;
}

.queue_details {
  margin-top: 15px;
  color: #8f90a6;
  background: #f2f2f5;
  padding: 15px;
  border-radius: 6px;
}

.call_timepicker {
  width: 100%;
}

.call_timepicker .rc-time-picker-input {
  padding: 4px 7px;
  height: 45px;
}

.call_icons td {
  border: none;
}

.call_icons .phone_active {
  color: white;
  background-color: #39d98a;
  padding: 10px;
  border-radius: 50%;
}

.call_icons .phone_stop {
  font-size: 19px;
}

.save_filter {
  border-radius: 5px !important;
  color: #8f90a6 !important;
  background: #ebebf0 !important;
  border-color: #ebebf0 !important;
  height: 54px !important;
}

.dashboard-v2-table th {
  padding: 15px 19px;
  width: 175px;
  font-weight: 600;
  font-size: 14px;
  vertical-align: middle !important;
}

/*.dashboard-v2-table th:first-child{
    max-width:15px;
 }*/

.dashboard-v2-table .table td {
  border: none !important;
  padding: 11px 19px;
  font-size: 14px;
  border-color: #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.dashboard-v2-table .table th {
  border: none !important;
  padding: 15px 19px !important;
  border-color: #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  font-size: 16px;
}

.dashboard-v2-table tbody:nth-child(even) {
  background: #fafafc; /*#ebebf073; */
}

.dashboard-v2-table tbody tr :first-child {
  font-weight: 600;
}

.dashboard-v2-table tbody .td-light {
  font-weight: normal !important;
}

.dashboard-v2-table .table tbody tr:hover {
  background-color: #fafafc;
}
/* .dashboard-v2-table td:nth-child(even) {
  background: #ebebf073;
} */

.dashboard-v2-table .table {
  border-radius: 1em;
  min-width: max-content;
  border-top: 0.2px solid #dee2e6;
}

.call_tabular:nth-child(even) {
  background-color: #f2f2f5;
}

.call_tabular {
  padding: 18px 9px;
}

.call_details_shadow {
  border-radius: 14px;
  box-shadow: 0 1px 5px #dee9fc, 0 1px 9px rgb(0 0 0 / 4%) !important;
}

.call_table_agami th {
  font-weight: 600;
  vertical-align: middle;
}

.vertical_call .rwt__tablist[aria-orientation="vertical"] {
  border-right: none;
}

.save_filter option {
  padding: 5px 9px 10px;
}

.pagination-dots {
  color: #8f90a6;
  font-size: 14px;
  margin-right: 15px;
  font-weight: bold;
  text-align: center;
}

.pagination-no {
  cursor: pointer;
  color: #8f90a6;
  font-size: 14px;
  margin-right: 15px;
  font-weight: bold;
  text-align: center;

  display: inline-block;
  min-width: 33.5px;
  height: 33.5px;
  padding-top: 6px;
}

.pagination-no.active {
  color: #3e7bfa;
  background: #ccddff;
  border-radius: 50%;
}

.pagination-no.disabled {
  cursor: not-allowed;
}

.map_filter {
  border-radius: 4px;
  font-weight: 500;
  padding: 16px 15px;
  border: 1px solid #ccddff;
  border-top: none;
  color: #9091a7;
}

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #3e7bfa !important;
  background: transparent !important;
}

.rwt__tab {
  transition: none;
  background: transparent;
  padding: 1rem 1rem !important;
  font-weight: 600;
  color: #8f90a6;
}

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  border-right: 3px solid #2f80ed !important;
}

.vertical_call .rwt__tab[aria-selected="true"] {
  color: #2f80ed;
  background: #fff;
  position: relative;
}

.vertical_call .rwt__tab[aria-selected="false"] {
  background: #f2f2f5;
}

.vertical_call .rwt__tab {
  border-bottom: 1px solid rgba(0, 82, 255, 0.08) !important;
  padding: 0.7rem 1rem !important;
}

.vertical_call .rwt__tabpanel {
  width: -webkit-fill-available;
}

.call_tabular h5 {
  padding: 0px 5px;
  text-transform: none !important;
  font-weight: 600 !important;
}

.calling_modal.modal-lg {
  max-width: 1300px !important;
}

.feedback-form-modal.modal-lg {
  max-width: 1550px !important;
}

.transform-90,
.transform-180 {
  font-weight: 600;
  font-size: 14px;
  color: black;
  letter-spacing: 0.3px;
  position: absolute;
}
.transform-90 {
  transform: rotate(-90deg);
}

.transform-180 {
  transform: rotate(0deg);
  left: 40%;
  bottom: 5px;
}

.transform-90-bar {
  left: 10px;
  top: 50%;
}

.rwt__tab[aria-selected="true"] {
  color: black;
  position: relative;
}

.rwt__tab:focus {
  background-color: transparent !important;
}

.graph_height {
  height: 500px;
  max-height: 500px;
  background: white;
  border-radius: 15px;
}

.filter_active .map_filter {
  border: 1px solid #3e7bfa;
  color: #3e7bfa;
}

.collapse_shadow {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.arbitration th {
  width: 10%;
}

.arbitration td {
  width: 10%;
}
.agent-download-margin .margin-csv .btn-filter {
  padding: 7px 10px !important;
}

.ml-m-10 {
  margin-left: -10px;
}

.loan-setting-new td {
  width: 25% !important;
}

.loan-setting-new .form-control,
.loan-setting-new-creditline .form-control {
  padding: 6px !important;
}

@font-face {
  font-family: myFirstFont, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-transform: unset;
}

.change-no-loans {
  margin-left: 17px;
  cursor: pointer;
}

.display-none {
  display: none;
}

.edit-number {
  margin: 0 6px 0 6px !important;
  width: 70px !important;
  display: inline-block !important;
  padding: 1px !important;
}

.postal-p-style p {
  line-height: unset;
  font-size: 12px;
  color: #888888;
  margin-bottom: 0px;
  font-family: myFirstFont, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.mt-m-14 {
  margin-top: -14px;
}

.container-fluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}

.a-down {
  font-size: 14px;
  font-weight: 700;
}

.collapse-bg {
  background: #fbfbfb;
}

.react-tagsinput-input {
  width: 165px !important;
}

.modules_res td {
  padding: 0.5rem;
}

.react-tagsinput .react-tagsinput-tag {
  background: #145388 !important;
  border-radius: 3px !important;
  padding: 6px 16px !important;
  margin-bottom: 0px !important;
  display: inline-block !important;
  font-size: 13px !important;
  color: white !important;
  border: initial !important;
}

.custom_bold {
  font-weight: 800;
}

.pd-left {
  padding-left: 20px;
}

.w-11 {
  width: 11%;
}

.w-8 {
  width: 8%;
}

.pr-20 {
  padding-right: 20px;
}

.flow-root {
  display: flow-root !important;
}

.draft_load {
  float: right;
  margin-top: -17px;
}

.img-hw {
  height: 550px;
  width: 550px;
}

.mr-7 {
  margin-right: 7px;
}
.ml-7 {
  margin-left: 7px;
}
.ml-1p {
  margin-left: 1%;
}
.card-height-100 {
  height: 100%;
}
.mb-5 {
  margin-bottom: 5%;
}

.color-grey {
  color: darkgrey !important;
  font-size: smaller !important;
}

.pr-5 {
  padding-right: 5%;
}

.icon-red-f-ai {
  color: red;
  font-size: 15px;
  cursor: pointer;
}

.icon-green-f-ai {
  color: green;
  font-size: 15px;
  cursor: pointer;
}

.m-3 {
  margin: 3%;
}

.auto-task-bottom .form-control {
  padding: 6px !important;
}

.auto-task-bottom td {
  width: 33% !important;
}

.icon-blue-fb-ai {
  color: #145388;
  font-size: 15px;
  cursor: pointer;
}

.table .sub_dict_variables_css td {
  background: #e8e8e8;
}

.icon-blue-fb-ai .simple-icon-plus {
  font-weight: 700;
  font-size: 13px;
}

.add-rule-bottom {
  display: block;
  background: #f8f8f8;
  padding: 11px;
  text-align: center;
  font-weight: 700;
}

/* .highcharts-container{
height: 300px!important;
} */

.highcharts-credits {
  display: none;
}

.simple-icon-shuffle {
  display: none;
}

.raphael-group-zKgArrHn {
  display: none;
}

g[class^="raphael-group-"][class$="-creditgroup"] {
  display: none !important;
}

.tm-ty {
  width: 30%;
  margin-left: 2%;
  margin-bottom: 1rem;
}

.blue {
  color: #0e5896 !important;
}

.raphael-group-4-background rect {
  fill: white;
  stroke: #ffffff;
}
.raphael-group-27-canvas rect {
  stroke: #ffffff;
}

.cd-logo {
  margin-top: -21px;
  width: 117px;
}

.fw-700 {
  font-weight: 700;
}

.card .card-body {
  padding: 1.5rem 2rem !important;
}

.fos-pd .card-body {
  padding: 1.5rem !important;
  border: 1px solid #e4e4eb;
  border-radius: calc(1.15rem - 1px);
}

.fos .card-body {
  padding: 4rem 0rem !important;
  border: 1px solid #e4e4eb;
  border-radius: calc(1.15rem - 1px);
}

@media (max-width: 500px) {
  .cd-logo {
    margin-top: -14px;
    width: 71px;
    margin-left: 13px;
  }
}

.navbar {
  background: #eef3fd !important;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.02) !important;
}

.search-table {
  position: relative;
  width: auto;
  border-radius: 20px;
  background: #f8f8f8;
}

.search-table input,
.search-table .portfolio-multi-select {
  border: initial;
  background: transparent !important;
  outline: medium none invert !important;
  outline: initial !important;
  padding: 0.5rem 1rem;
  line-height: 1.75;
  font-size: 0.8rem;
  width: 93%;
  color: #212121;
}

.search-table .search-icon {
  font-size: 11px;
  color: #707070;
  /*color: #d7d7d7;*/
  position: absolute;
  width: 78px;
  height: 37px;
  bottom: 0px;
  line-height: 2.2rem;
  right: 3px;
  text-align: center;
  cursor: pointer;
  border-radius: 0px 17px 17px 0px;
  padding-right: 6px;
}

.search-icon .custom-icon {
  font-size: 9px;
}

.search-table .custom-height {
  height: 37px;
}

.search-table .custom-width {
  width: 250px;
}

.text-center {
  text-align: center;
}

#cu_id .optionListContainer {
  position: relative;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.dialer_refresh {
  background: white;
  border: none;
  margin-left: 20vh;
  cursor: pointer;
  color: #5185ea;
}

#panelContainer {
  position: fixed;
  /* top: 56vh; */
  right: 315px;
  bottom: 452px;
  z-index: 1111;
  width: fit-content;
}

.search-input-final {
  display: grid;
  border-radius: 39px;
  width: auto;
  border: 1px solid #e4e4eb;
  grid-template-columns: 1.65rem 26.3fr;
  align-items: center;
  padding: 0rem 0.8rem 0rem 0.8rem;
  justify-content: center;
  height: 2.5rem;
}

.search-input-final input {
  border: none;
  outline: none;
}

.did-number {
  width: 160px;
  height: inherit;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  cursor: pointer;
  border: 1px solid #e4e4eb;
  border-radius: 8px;
  font-size: 12px;
  color: #28293d;
  background-color: transparent;
}

.mt-10 {
  margin-top: -7px;
}

.ml-39 {
  margin-left: 39%;
}

.mt-41pt {
  margin-top: -41px;
}

.flex-1-1 {
  border-left: 1px solid #ededed !important;
  flex: 1 1;
  box-shadow: none !important;
}

.f-size-12 {
  font-size: 12px;
}
.f-size-1rem {
  font-size: 1rem;
}
.red {
  color: red !important;
}

.wd-30 {
  width: 30%;
}

.pd-8 {
  padding: 8px !important;
}

.color-danger {
  background: red !important;
}

.btn-save_filter {
  background: #0a2a45 !important;
}

#dpdSelectAll {
  font-weight: bold;
}

.dm-5 {
  margin: 5px;
}

#multiselectContainerReact {
  background: #ffffff !important;
}

.meter {
  height: 50px;
}

.ml-6 {
  margin-left: 6%;
}

.ml-50 {
  margin-left: 50%;
}

.mr-5 {
  margin-right: 5%;
}

.pb-80 {
  padding-bottom: 80px;
}

.cd {
  margin-top: -12px;
}

.f-size-8 {
  font-size: 8px;
  padding-right: 5px;
}
.chat-box {
  display: inline-block;
  border: #2e7bc6;
  background-color: #2e7bc6;
  border-radius: 5px;
  color: white;
  padding: 6px;
}

.trash {
  font-size: 20px;
  color: red;
  padding: 5px;
}

.pd-bder {
  padding: 6px 12px !important;
  border-radius: 5px !important;
}

.pd-bder2 {
  padding: 3px 10px !important;
  border-radius: 5px !important;
  margin-left: 8px;
}

.pad-l-r {
  padding: 1.5rem 3.5rem !important;
}

.pagination-bottom {
  margin-top: 3%;
}

.pagination {
  overflow-x: auto;
}

.rt-tbody {
  margin-top: 8px;
}

.last-remark {
  display: block;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.last-remark2 {
  display: block;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.last-remark:hover {
  overflow: visible;
}

.btn-plus-minus {
  margin-left: 7px;
  float: right;
  padding: 4px 15px !important;
}

.bulk-upload .modal-header {
  border-bottom: none !important;
}

.bulk-upload .modal-footer {
  border-top: none !important;
  padding: 0.6rem !important;
}

.process-button {
  border: 1px solid #145388 !important;
  color: white !important;
  background: #145388 !important;
  cursor: pointer;
  width: 72%;
  margin: auto;
}

.cd-table td {
  width: 50%;
}

.cd-table .form-control {
  padding: 3px;
}

.btn-sav {
  margin-bottom: 20px;
  padding: 6px 14px !important;
  border-radius: 4px !important;
}

.padding-input input {
  padding: 7px !important;
}

.padding-input .react-datepicker__input-container input {
  padding: 9px !important;
}

.padding-input select {
  padding: 7px !important;
}

html {
  scroll-behavior: smooth;
}

.sub-head {
  font-weight: 600;
  color: #145388;
}

.optional-button {
  padding: 0px !important;
  font-size: 18px !important;
  background-color: white !important;
  color: #145388 !important;
  border: none !important;
  font-weight: 600 !important;
}

.f-size {
  font-size: 15px;
}

.ql-toolbar.ql-snow {
  display: none !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc !important;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-20 {
  font-size: 20px;
}

.auth-signup-card .form-side {
  width: 60% !important;
  padding: 37px !important;
}

.auth-signup-card .form-control {
  padding: 0.5rem;
}

.error-show {
  margin-bottom: 0px;
}

.remark-box {
  /* border-bottom: 1px solid lightgrey; */
  margin-bottom: 15px;
}

.remark-scroll {
  margin-top: 10px;
  overflow-y: auto;
  height: 56vh;
  margin-right: -20px;
}

.remark-modal .modal-body {
  padding-bottom: 0px !important;
  flex: auto !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .c_shadow {
  box-shadow: none !important;
} */

.select-style {
  border: initial !important;
  background: #f8f8f8 !important;
  outline: initial !important;
  padding: 0.5rem 0.4rem !important;
  line-height: 1.75 !important;
  font-size: 0.8rem !important;
  width: 95% !important;
  color: #514f4f !important;
}

.didRequestConfirm {
  font-size: 14px;
  line-height: 1.6rem;
  color: indianred;
}

.modalH5 {
  font-size: 1.15rem;
  font-weight: 500;
  margin-bottom: 0;
}

.cursor {
  cursor: pointer !important;
}

.reminder_time_red .call_timepicker .rc-time-picker-input {
  border: 1px solid #ff0000;
}

.quill .ql-editor {
  height: 115px !important;
}

.c_shadow select.form-control:not([size]):not([multiple]) {
  border: 1px solid #f8f8f8 !important;
  background: #f8f8f8 !important;
  padding: 7px !important;
  height: calc(2.25rem) !important;
}

.pulse-box {
  float: right;
  width: 50%;
  height: 30px;
  display: flow-root;
  justify-content: center;
  align-items: center;
}
/* pulse in SVG */
svg.pulse-svg {
  overflow: visible;
}
svg.pulse-svg .first-circle,
svg.pulse-svg .second-circle,
svg.pulse-svg .third-circle {
  fill: #f00;
  transform: scale(0.5);
  transform-origin: center center;
  animation: pulse-me 3s linear infinite;
}
svg.pulse-svg .second-circle {
  animation-delay: 1s;
}
svg.pulse-svg .third-circle {
  animation-delay: 2s;
}
/* pulse in CSS */
.pulse-css {
  width: 7px;
  height: 7px;
  border-radius: 25px;
  background: tomato;
  position: relative;
}

#f-pulse-css {
  background: #145388 !important;
}

#f-pulse-css:before,
#f-pulse-css:after {
  background-color: blue;
}

.pulse-css:before,
.pulse-css:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 25px;
  background-color: tomato;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: scale(0.5);
  transform-origin: center center;
  animation: pulse-me 3s linear infinite;
}
.pulse-css:after {
  animation-delay: 2s;
}
@keyframes pulse-me {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}

.pulse-container {
  display: contents;
}

.mahroon {
  color: #c80707;
}

.b-radius {
  border-radius: 50% !important;
  padding: 8px 10px !important;
  background-color: #145388 !important;
  border-color: #145388 !important;
}

.b-left {
  top: 55%;
  position: fixed;
  left: 16%;
}

.main-hidden .b-left {
  top: 62%;
  position: fixed;
  left: 5%;
}

.b-right {
  right: 10px;
  position: fixed;
  top: 55%;
}

.bl-right {
  right: 2%;
  position: fixed;
  top: 55%;
}

.btn-filter {
  border-color: #f8f8f8 !important;
  padding: 8px 15px !important;
  border-radius: 0px !important;
  background-color: #f8f8f8 !important;
  margin-right: 11px;
  color: #514f4f !important;
}

.filter-modal .modal-header {
  height: 66px !important;
  padding: 22px !important;
}

.filter-body .rc-slider {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.filter-body h5 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 14px;
}

.filter-modal .modal-header .modal-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}

.filter-body .rc-slider .rc-slider-step {
  height: 5px !important;
}

.filter-body .rc-slider .rc-slider-handle {
  width: 15px !important;
  height: 15px !important;
  margin-top: -5px !important;
}

.filter-front {
  display: inline-block;
  width: max-content;
  border-radius: 3px;
  padding: 7px 12px;
  background: #f8f8f8;
  margin-bottom: 10px;
}

.blue-id {
  color: #0e5896 !important;
}

.ml10 {
  margin-left: -10px;
}

.btnsm {
  padding: 7px !important;
  border-radius: 3px !important;
  width: 20%;
}

.flex-auto {
  display: flex;
  width: auto !important;
}

.flex-auto select {
  width: 70% !important;
}

.width80 {
  width: 80px !important;
}

.filter-modal .react-datepicker__input-container input {
  padding: 0.5rem !important;
}

.filter-modal .modal-footer {
  padding: 15px !important;
}

@media (min-width: 1500px) {
  .b-left {
    top: 62%;
    position: fixed;
    left: 15.5%;
  }

  .main-hidden .b-left {
    top: 62%;
    position: fixed;
    left: 5%;
  }
}

@media (min-width: 1650px) {
  .b-left {
    top: 62%;
    position: fixed;
    left: 14%;
  }

  .main-hidden .b-left {
    top: 62%;
    position: fixed;
    left: 4%;
  }
}

@media (min-width: 1750px) {
  .b-left {
    top: 62%;
    position: fixed;
    left: 13%;
  }
}

@media (min-width: 1900px) {
  .b-left {
    top: 62%;
    position: fixed;
    left: 6.5%;
  }
}

.update-customer {
  color: #1e8a1e;
  float: right;
  padding: 9px;
}

.bg-white {
  background: #ffffff;
}
.bg-commn {
  background: #f8f8f8;
}
.mb-15 {
  margin-bottom: 15px;
}

.cross {
  font-size: 15px;
  color: red;
  cursor: pointer;
}

.ml-40p {
  margin-left: 40%;
}

.remark-footer {
  display: block !important;
}

.pulse2 {
  display: -webkit-inline-box !important;
}

.sidebar .main-menu ul li i {
  font-size: 13px !important;
}

.main-hidden .sidebar .main-menu ul li i {
  line-height: 44px;
  padding-left: 78%;
}

.main-hidden .sidebar .main-menu ul li span {
  display: none;
}

/*.main-hidden .sidebar .main-menu ul li a {
height: 60px!important;
}*/

.sidebar .main-menu ul li a {
  padding-left: 24px !important;
  display: block !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  border-bottom: 0px solid #f3f3f3 !important;
  color: #212121;
  transition: color 300ms;
  transition: background 300ms;
  height: 46px !important;
}

.badge.badge-top-left-ai {
  top: -14px;
  left: 13px;
  font-size: 11px;
  letter-spacing: 0.5px;
}

.button-flex-root {
  display: flow-root !important;
}

.sidebar .main-menu ul li:last-of-type a {
  border-bottom: 0px solid #f3f3f3 !important;
}

.sidebar .main-menu ul li.active:after {
  height: 39px !important;
  top: 49% !important;
}

.sidebar .main-menu ul li.active {
  /* background: #f8f8f8; */
  /* background: #72a5f8; */
  border-radius: 5px;
  background: linear-gradient(147.14deg, #5b8def 6.95%, #5b8def 6.96%, #0063f7 93.05%);
  opacity: 0.8;
  box-shadow: 0px 4px 5px 1px rgb(64 135 247 / 39%);
}

.sidebar .main-menu ul li.parentactive {
  /* background: #f8f8f8; */
  /* background: #72a5f8; */
  border-radius: 5px;
  background: #ccddff;
  opacity: 0.8;
  box-shadow: 0px 4px 5px 1px rgb(64 135 247 / 39%);
}

/* .sidebar .main-menu ul li.active a i{
  padding: 6px;
  background: #6d6e92;
  border-radius: 4px;
} */

.id-small {
  display: block;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.id-small:hover {
  overflow: visible;
}

.underline {
  text-decoration: underline;
}

.navbar #notificationDropdown {
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.04);
  width: 430px !important;
  padding: 1.5rem !important;
  height: 350px !important;
  right: 15px;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 1100px !important;
  }
}

.btn-play {
  float: right;
  margin-top: 16px;
  background: white;
  border: white;
  margin-right: 50%;
  cursor: pointer;
}

.btn-play i {
  font-size: 22px;
  color: blue;
}

.btn-play:disabled i {
  color: grey;
}

.btn-play-ctc {
  /*float: right;*/
  margin-top: 16px;
  background: white;
  border: white;
  /*margin-right: 50%;*/
  cursor: pointer;
}

.btn-play-ctc i {
  font-size: 22px;
  color: blue;
}

.btn-play-ctc:disabled i {
  color: grey;
}

.img-voice {
  position: absolute;
  left: -95px;
  bottom: 67%;
}

.pause-down {
  position: absolute;
  bottom: -13%;
  left: 29%;
}

.optional-case {
  width: 100%;
  text-align: left;
}

.w-100 {
  width: 100%;
}

.f-12 {
  font-size: 12px;
}

.highlight-remark {
  animation: background-fade 5s forwards;
}

@keyframes background-fade {
  9.9% {
    background: #ffa095;
  }
  100% {
    background: #fff;
  }
}

.padding-notification select {
  padding: 0.5rem !important;
}

.update-customer2 {
  font-weight: 600;
  color: #f71616;
  float: right;
  padding: 1px 25px;
}

.error-msg {
  font-weight: 600;
  color: #f71616;
  float: left;
  padding: 9px;
}

.customer-pending-message {
  font-weight: 600;
  color: #ffa500;
  float: left;
  padding: 9px;
}

.float-button-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 100px;
  width: 65px;
  cursor: pointer;
}
.float-button-container .open-button {
  position: absolute;
  background: #145388;
  height: 70px;
  width: 70px;
  bottom: 0;
  transform: scale(0.8, 0.8);
  border-radius: 100px;
  z-index: 999;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.float-button-container .open-button:before,
.float-button-container .item01:before,
.float-button-container .item02:before,
.float-button-container .item03:before,
.float-button-container .item04:before {
  position: absolute;
  font-family: "FontAwesome", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 2rem;
  top: 50%;
  left: 50%;
  color: black;
  transform: translate(-50%, -50%);
}
.float-button-container .open-button:before {
  content: "\f0c9";
  font-size: 1.8rem;
  color: white;
}
.float-button-container .item01:before {
  color: white;
  content: "\f2c0";
}
.float-button-container .item02:before {
  color: white;
  content: "\f086";
}
.float-button-container .item03:before {
  color: white;
  content: "\f002";
}
.float-button-container .item04:before {
  color: white;
  content: "\f007";
}
.float-button-container .item01,
.float-button-container .item02,
.float-button-container .item03,
.float-button-container .item04 {
  position: absolute;
  background: #145388;
  height: 80px;
  width: 80px;
  border-radius: 100px;
  bottom: 0;
  transform: scale(0.4, 0.4);
  transition: all 0.3s cubic-bezier(0.68, -0.15, 0.265, 1.15);
}
.float-button-container .item01:hover,
.item02:hover,
.item03:hover,
.item04:hover {
  background: #c4315f;
}
.float-button-container:hover {
  height: 100%;
}
.float-button-container:hover .open-button {
  transform: scale(1, 1);
}
.float-button-container:hover .item01,
.float-button-container:hover .item02,
.float-button-container:hover .item03,
.float-button-container:hover .item04 {
  transform: scale(0.6, 0.6);
}
.float-button-container:hover .item02 {
  bottom: 90px;
}
.float-button-container:hover .item03 {
  bottom: 160px;
}
.float-button-container:hover .item04 {
  bottom: 230px;
}
.float-button-container:hover .item01 {
  bottom: 300px;
}

.ds {
  display: inline-block;
}

.detail-block {
  margin-right: 9px;
  padding: 4.5px 9px !important;
  border-radius: 5px !important;
}

.display-inline {
  display: inline;
}

.f-size-15 {
  font-size: 15px !important;
}

/* .bg-diff {
  background: #f5f5f5;
} */

.bg-diff .nav-item {
  margin-left: 24px;
}

.i-download {
  color: black;
  font-size: 17px;
}

.margin-csv {
  cursor: pointer;
}

.margin-csv .btn-filter {
  padding: 14px 13px !important;
}

.br-1px {
  border-right: 1px solid #e3e3e3;
}

.filter-no {
  bottom: 63%;
  border-radius: 50%;
  background: #004fc4;
  position: absolute;
  left: auto;
  right: 4.5%;
  font-size: 12px;
  color: white;
  padding: 1px 6px;
}

.see-bottom {
  padding-top: 9px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-color: rgba(33, 33, 33, 0.15);
  background: white;
  margin-left: -25px;
  margin-right: -25px;
}

.headcol {
  background: white;
  position: absolute;
  width: 6em;
  right: 0;
  top: auto;
}

.table-width .table-responsive {
  width: 94%;
}

.table-width .table thead th {
  border-bottom: 0px solid #dee2e6;
}

.suggestions-list {
  right: 13px;
  width: 304px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.29), 0 1px 1px rgba(0, 0, 0, 0);
  position: absolute;
  list-style: none;
  padding: 0;
  max-height: 233px;
  overflow-y: auto;
  z-index: 998;
  top: 75px;
  border-radius: 5px;
}

.user-input {
  position: relative;
}

.suggestion {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  color: rgb(65, 65, 65);
  cursor: pointer;
}
.suggestion:hover {
  color: black;
  z-index: 99999;
  background: #efeded;
}

.no-sugg {
  float: right;
  margin-top: 13px;
  margin-right: 6px;
}

.selected {
  background-color: rgb(20, 83, 136);
  color: rgb(255, 255, 255);
}

.search-table2 {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0);
  position: relative;
  width: 300px;
  border-radius: 20px;
  background: #ffffff;
  border: 1px solid #f8f8f8;
}

.search-table2 input {
  border: initial;
  background: transparent !important;
  outline: medium none invert !important;
  outline: initial !important;
  padding: 0.5rem 1rem;
  line-height: 1.75;
  font-size: 0.8rem;
  width: 93%;
  color: #212121;
}
.search-table2 .search-icon {
  font-size: 17px;
  border-radius: 10px;
  color: #d7d7d7;
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -10px;
  right: 3px;
  text-align: center;
  /*cursor: pointer;*/
}

.timepicker .rc-time-picker-input {
  border-radius: 0px;
  padding: 18px !important;
  font-size: 13px;
}

.c-pointer {
  cursor: pointer;
}

.height-upload .table-responsive {
  height: 60vh;
}

.i-button {
  cursor: pointer;
  background: #145388;
  padding: 1px 5px;
  color: white;
  border-radius: 3px;
}

.filter-no2 {
  margin-left: 2px;
  top: 27px;
  position: absolute;
  border-radius: 100%;
  display: inline;
  background: #145388;
  font-size: 12px;
  color: white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.filter-download {
  font-size: 16px;
  margin-left: 15px;
}

.h-vh {
  height: 100vh !important;
}

.react-select__control .css-1rtrksz {
  padding: 0px 8px !important;
}

.p-absolute {
  position: absolute;
}

.w-60p {
  width: 60%;
}

.w-23p {
  width: 23%;
}

.w-10 {
  width: 10%;
}

.btn-sav2 {
  padding: 8px 14px !important;
  border-radius: 4px !important;
}

.auto-task .react-datepicker__input-container input {
  padding: 8px !important;
}

.icon-red-f {
  position: absolute;
  color: red;
  font-size: 15px;
  cursor: pointer;
}

.icon-blue-f {
  position: absolute;
  bottom: 32px;
  color: #145388;
  font-size: 18px;
  cursor: pointer;
}

.auto-task .form-control {
  padding: 8px !important;
}

.disabledcontent {
  pointer-events: none;
  opacity: 0.6;
  display: block;
}

.blockContent {
  pointer-events: none;
}

.template-css input {
  padding: 8px !important;
}

.blue-c {
  font-weight: 600;
  color: #145388;
}

.bg-template {
  background: #fbfbfb;
  padding: 18px;
}

.cd-table .react-datepicker__input-container input {
  padding: 4px !important;
}

.cd-table .rc-time-picker {
  width: 100% !important;
}

.cd-table .timepicker .rc-time-picker-input {
  padding: 13px !important;
}

.nav-link {
  cursor: pointer;
}

.open-button-bottom {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.21), 0 1px 6px rgba(0, 0, 0, 0.02);
  background-color: #555;
  color: white;
  padding: 10px 14px;
  border: none;
  cursor: pointer;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  right: 85px;
  width: 229px;
}

.disable-open-button-bottom {
  display: none;
}

.open-button-bottom-close {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.21), 0 1px 6px rgba(0, 0, 0, 0.02);
  font-size: 18px;
  background-color: #555;
  color: white;
  padding: 6px 12px;
  border: none;
  cursor: pointer;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  right: 50px;
  width: 34px;
}

.sc-down {
  cursor: pointer;
  top: 20px;
  font-size: 29px;
  position: absolute;
  right: 61px;
}

.create-call .modal-header .close {
  padding: 0rem !important;
  margin: 0rem 0rem -1rem auto !important;
}

.create-call .modal-title {
  margin-right: 43px !important;
}

.play-btn {
  background: transparent;
  border: none;
}

.pd-32 {
  padding: 32px;
}

.start-timer {
  margin-left: 25%;
  padding-left: 19px;
  font-weight: 600;
  font-size: 13px;
}

.start-timer-ctc {
  font-weight: 600;
  font-size: 13px;
}

@media screen and (max-width: 575px) {
  .start-timer {
    margin-left: 0%;
  }
  .start-timer::after {
    content: "\a";
    white-space: pre;
  }

  #end-btn-modal {
    content: "\a";
    white-space: pre;
  }
}

.disabled .btn {
  background: #c0c0c0 !important;
  border-color: #c0c0c0 !important;
  cursor: no-drop !important;
}

.navbar .header-icon.notificationButton .count {
  padding: 0px 4px !important;
  font-size: 9px;
  color: #145388;
  border: 1px solid #145388;
  border-radius: 10px;
  position: absolute;
  width: auto !important;
  height: 15px;
  text-align: center;
  font-weight: 700;
  top: 0px;
  right: -1px !important;
  line-height: 14px;
}

.s-close-popup {
  text-align: center;
  padding-top: 7%;
  padding-bottom: 7%;
}

.closed {
  color: #c61414;
}

.reopen {
  color: #145388;
}

.cross-filter {
  cursor: pointer;
  margin-left: 8px;
}

.ml-43px {
  font-weight: bolder;
  margin-left: 29%;
  font-size: 14px;
}

.mtop-2 {
  margin-top: 2px;
  margin-right: 9px;
}

.mtop-1 {
  margin-top: 2px;
  margin-right: 9px;
  margin-left: 20px;
}

.mbottom-2 {
  margin-top: 2px;
  margin-right: 9px;
}

.selectedbg {
  border-radius: 3px;
  font-weight: 550;
  margin-bottom: 9px;
  display: block;
  background: #f8f8f8;
  padding: 13px;
  text-align: center;
  font-size: 1rem;
}

.selectedbg2 {
  /* color: #2a78b9; */
  cursor: pointer;
  font-weight: 500;
  background: #145388;
  color: white;
  padding: 6px;
  border-radius: 4px;
  margin-left: 8px;
  outline: none;
  border: none;
}

@media (min-width: 992px) {
  .email-size {
    max-width: 800px !important;
  }
}

.d-grid {
  overflow: auto;
  height: auto;
  max-height: 360px;
}

.selected_loan_ids li {
  border: 1px solid #e6eaf6;
  vertical-align: middle;
  padding: 16px;
  list-style: none;
  text-align: center;
}

.selected_loan_ids li span {
  vertical-align: middle;
  font-weight: 500;
  color: rgb(0, 99, 247);
  padding: 0.2rem 0.3rem;
  background: rgb(229, 240, 255);
  letter-spacing: 0.2px;
  border-radius: 2px;
  border: 4px solid rgb(243 243 243);
}

.custome-linked-loan-table .d-grid {
  max-height: unset;
}

.mt-6 {
  margin-top: 2rem !important;
}

.mt-minus11 {
  margin-top: -11px;
}

.c-red {
  color: red;
}

.charges {
  margin-bottom: 0px;
  float: right;
  margin-top: 34px;
  color: #ca3939;
}

.preview-box {
  position: relative;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  padding: 40px 15px 20px;
  font-weight: 500;
  margin: 10px 0;
  color: #333;
}

.preview-title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  font-weight: 600;
  border: 1px solid #ddd;
  border-top: 0;
  border-left: 0;
  border-radius: 5px 0 5px 0;
  padding: 5px 12px;
  letter-spacing: 1px;
}

.textarea-html {
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  width: 100%;
  flex: 1;
  display: flex;
  margin: 10px 0;
  min-height: 100px;
  padding: 10px;
  font-size: 16px;
}

.btnsm-html {
  padding: 0.4rem 1rem !important;
  font-size: 13px !important;
  border-radius: 3px !important;
}

.update-template {
  color: #1e8a1e;
  padding: 9px;
}

.bb-1 {
  border-bottom: 1px solid #d0cece !important;
}

.bt-1 {
  border-top: 1px solid #dad4d4 !important;
}

.br-1 {
  border-right: 1px solid #dad4d4 !important;
}

.bl-1 {
  border-left: 1px solid #dad4d4 !important;
}

.case-rem-table tr th {
  text-align: center;
  min-width: 250px !important;
  width: 250px !important;
  max-width: 250px !important;
}

.case_reminder .react-datepicker__input-container input {
  padding: 0.5rem !important;
}

.from-to-btn {
  border-radius: 0px !important;
  padding: 8px !important;
}

.case-pencil {
  font-size: 14px;
}

.new_comments {
  padding: 0.5rem !important;
  margin-right: 0px !important;
}

.e-richtexteditor .e-rte-srctextarea {
  min-height: 811px !important;
}

.cd-logo-side {
  margin-bottom: 13px;
  margin-left: 53px;
  margin-top: 6px;
  width: 75px;
}

.main-hidden .navbar .menu-button {
  margin-left: 49px;
}

.main-hidden .cd-logo-side {
  margin-bottom: 20px;
  margin-left: 162px;
  margin-top: 6px;
  width: 57px;
}

@media (max-width: 1439px) {
  .main-hidden .cd-logo-side {
    margin-bottom: 20px;
    margin-left: 148px;
    margin-top: 6px;
    width: 50px;
  }
}

.card-height-100 .pb-30p {
  padding-bottom: 22% !important;
}

.pt-h-23vh {
  padding-top: 23vh;
}

.h-82vh {
  height: 82vh;
}

.retry-button {
  cursor: pointer;
  font-size: 15px;
  margin-right: 18px;
  background: #2c82ca;
  color: white;
  padding: 11px;
  border-radius: 50%;
}

.from-date {
  float: right;
  margin-top: 7px;
  margin-right: -15px;
}

.bg-whiteimp {
  background: white !important;
}

.unread .bs-notification {
  box-shadow: 0 1px 5px rgba(6, 11, 13, 0.06), 0 3px 4px rgba(0, 8, 2, 0.02) !important;
}

.unread .bg-notification {
  background: #f9f9f9;
}

.ml-m-16 {
  margin-left: -16px;
}

.detail-block2 {
  padding: 7.2px 9px !important;
  border-radius: 2px !important;
  background-color: #ffffff !important;
  border-color: #d7d7d7 !important;
  color: #484848 !important;
}

.detail-block2 span {
  font-size: 17px;
}

.auto-task td {
  width: 15% !important;
}

.footer-vh {
  padding-top: 10vh;
}

@media (min-width: 1100px) {
  .footer-vh {
    padding-top: 22vh;
  }
}

@media (min-width: 1200px) {
  .footer-vh {
    padding-top: 32vh;
  }
}

@media (min-width: 1300px) {
  .footer-vh {
    padding-top: 39vh;
  }
}

@media (min-width: 1400px) {
  .footer-vh {
    padding-top: 32vh;
  }
}

/*predictor styling*/

#background-predictor {
  background: #f8f8f8 !important;
}

#background-predictor .mb-4 .justify-content-between {
  cursor: pointer;
}

#background-predictor .mb-4 .justify-content-between:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out 0s;
}

#recovery-percent-no {
  margin-left: 30px !important;
}

#rate-chart-pred .chart-container {
  height: auto !important;
}

#rate-chart-pred .chart-container tspan,
#rate-chart-pred .chart-container text {
  letter-spacing: 1px;
  font-family: "open-sans", sans-serif;
  font-weight: bold;
  color: black !important;
}

#rate-chart-pred:hover {
  transition: none !important;
  transform: none !important;
}

.navbar .navbar-logo-new {
  display: none;
}

.mark-location-box {
  position: relative;
  display: inline-grid;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.07);
  transition: box-shadow 0.5s;
  width: 46%;
  border-radius: 4px;
  padding: 14px;
  border: 1px solid #f3f2f2;
  margin: 8px;
  min-height: 200px;
}

.check-location {
  right: 3px;
  position: absolute;
  top: 14px;
}

.marked-success {
  display: block;
  margin: 19px;
  font-size: 14px;
  color: #045f04;
}

@media (max-width: 700px) {
  .mark-location-box {
    width: auto;
  }

  .mv-mt-7 {
    margin-top: 7px;
  }

  .mv-tab-22 {
    width: 22% !important;
  }

  .mv-tab-34 {
    width: 34% !important;
  }

  .mv-mr-2 {
    margin-right: 0.5rem !important;
  }

  .mv-short {
    white-space: nowrap;
    width: 66px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
  }

  .mv-search {
    width: 51%;
    margin-bottom: 8px;
    margin-top: 26px;
  }

  .mv-w-50p {
    width: 50%;
  }

  .mv-w-91p {
    width: 91%;
  }

  .mv-inline-flex {
    display: inline-flex;
  }

  .mv-mr {
    margin-right: 0px !important;
  }

  .mv-bulk {
    margin-top: 17px;
    display: flex;
    overflow-x: scroll;
  }

  .mv-bulk .detail-block {
    margin-bottom: 0px !important;
  }

  .b-left {
    left: 0;
    z-index: 1;
  }

  .b-right {
    right: 0px;
    z-index: 1;
  }

  .mv-automate-task {
    display: inline-flex;
    margin-top: 11px;
  }

  .mv-automate-task .detail-block {
    margin-bottom: 0px !important;
  }

  .icon-red-f {
    position: unset !important;
  }

  .p-absolute {
    position: unset !important;
  }

  .auto-task td {
    padding: 4px;
    width: 50% !important;
    display: inline-block !important;
  }

  .auto-task .w-10 {
    width: 87px !important;
  }

  .mv-ml-m-5 {
    margin-left: -5px;
  }

  .mv-mt-13 {
    margin-top: 13px;
  }

  .mv-mt-10 {
    margin-top: 10px;
  }

  .start-timer {
    padding-left: 0px;
    font-weight: 600;
    font-size: 12px;
  }

  .btn-play {
    float: unset;
  }

  .mv-pause .btn-play {
    margin-left: -10px;
  }

  .mv-pause {
    margin-top: 20px;
  }

  .mv-mt-20 {
    margin-top: 20px;
  }

  .pause-down {
    display: none;
  }

  .img-voice {
    position: absolute;
    left: unset;
    bottom: 74%;
    right: 0%;
  }

  .mv-bulk-modal .ml-m-16 {
    margin-right: 16px;
    margin-left: 13px;
  }

  .mv-bulk-modal .col-sm-4 {
    margin-right: 16px;
    margin-left: 13px;
  }

  .navbar #notificationDropdown {
    width: 366px !important;
  }

  .mv-pr-31 {
    padding-right: 31px;
  }

  .navbar .navbar-logo-new {
    display: block;
    top: 24px;
    width: 124px;
    height: 39px;
    position: absolute;
    left: 43px;
    margin: 0 auto;
  }

  .navbar-logo {
    display: none;
  }
}

@media (max-width: 334px) {
  .mv-dis-none {
    display: none !important;
  }
}

/*geo dashboard styling*/

#g-pie-chart-cont .chart-container {
  height: auto !important;
}

#zoom-btn-cont {
  margin-bottom: 20px;
}

#zoom-middle {
  width: 15%;
  margin-left: auto;
  margin-right: auto;
}

#zoom-in {
  width: auto;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 50%;
  background: #145388;
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 25px;
  margin-left: 10px;
  margin-right: 5px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#zoom-in:hover {
  background: #1db7ee;
}

#zoom-out {
  width: auto;
  padding: 5px 17px;
  cursor: pointer;
  border-radius: 50%;
  background: #145388;
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 25px;
  margin-left: 5px;
  margin-right: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#zoom-out:hover {
  background-color: #1db7ee;
}

#geography {
  stroke: #0c3c64;
  stroke-width: 0.5px;
}

#map-marker {
  fill: white;
  border: 0.1px solid black;
  cursor: crosshair;
}

.prevent-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-sav-filter {
  margin-right: 10px;
  padding: 7px 15px !important;
  border-radius: 4px !important;
}

.btn-save-filter {
  margin-right: 10px;
  padding: 7px 31px !important;
  border-radius: 4px !important;
}

/*call navbar*/

#call-navbar {
  padding-bottom: 20px;
}

#exotel-nav {
  font-size: 14px;
}

#manual-nav {
  font-size: 14px;
}

#btn-pause {
  float: none;
}

#s-timer {
  margin-left: 0px !important;
}

#biz-loan-filter {
  overflow-y: scroll;
  height: auto;
  max-height: 195px;
}

.campaignOptions {
  border: 1px solid #e6e6e6 !important;
  height: 2.2rem !important;
  margin-top: 5px !important;
}

/*city filters*/

#city-choices {
  padding: 7px 10px;
  width: 12rem;
  display: inline-block;
  border: 1px solid #eee;
  margin: 5px;
  margin-left: 0px;
}

#city-choice-del {
  color: red;
  cursor: pointer;
  padding: 2px;
  float: right;
}

#sug-city {
  width: 100%;
  border: 1px solid #eee;
  background: white;
  cursor: pointer;
  text-transform: capitalize;
  padding: 5px 10px;
}
#sug-city:hover {
  background: #eee;
}

#sug-city-close {
  width: 100%;
  border: 1px solid red;
  margin-top: 1px;
  background: white;
  cursor: pointer;
  padding: 5px 10px;
  color: red;

  text-align: center;
}
#sug-city-close:hover {
  background: #eee;
}

#loan_type_inner {
  text-transform: capitalize !important;
}

#reminder-calling-module {
  display: inline-block;
}
#reminder-calling-module:after {
  content: "\a";
  white-space: pre;
}

#ae-port {
  text-transform: none !important;
}
.scroll-box-x {
  flex-wrap: unset !important;
  overflow-x: auto !important;
  display: -webkit-box !important;
}

.address-height {
  overflow: hidden;
  height: fit-content;
  white-space: pre-line;
}

.address-height td span {
  max-height: 135px;
  overflow: auto;
  display: block;
}

/*filter indicator circle*/
#f-active-circle {
  margin-left: 10px;
  /*position: relative;
    top:-10px;*/
  position: absolute;
  padding: 3.2px;
  font-size: 1px;
  background: #145388;
  border-radius: 20px;
}

/*for number of attempt*/
#noa_background {
  font-weight: bold;
}

/*for call modal table header*/

#call-mod-h-grid-header {
  font-size: 19px;
  color: black;
  padding-bottom: 12px;
}

#call-mod-h-grid,
#send-comm-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  font-size: 15px;
  border-top: 1px solid #d0caca;
  border-left: 1px solid #d0caca;
}

#call-mod-h-grid div,
#send-comm-grid div {
  text-align: center;
  padding: 10px;
  /*border:1px solid black;*/
  border-bottom: 1px solid #d0caca;
  border-right: 1px solid #d0caca;
}

@media (max-width: 992px) {
  #call-mod-h-grid {
    display: inline;
  }

  #call-mod-h-grid div {
    text-align: left;
    padding: 5px 10px;
    border: 1px solid #145388;
  }

  .create-call .modal-title {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

#c-nav-header {
  width: 75%;
}

@media screen and (max-width: 575px) {
  #c-nav-header {
    width: 100%;
  }
}

.back-button-css {
  position: relative;
  top: 2px;
  font-size: 20px;
  margin-right: 15px;
  color: #395c7b;
}

@media screen and (max-width: 575px) {
  .mv-w-100 {
    width: 100%;
  }
}

#document_header {
  text-align: left;
  color: #145388;
}

#document_header span {
  color: red;
}

.mx-height300 {
  max-height: 300px !important;
  overflow: auto;
}

.disabled-submit {
  background: #c0c0c0 !important;
  border-color: #c0c0c0 !important;
  cursor: no-drop !important;
}

.case-l-head {
  color: #145388;
  font-size: 17px;
  font-weight: 550;
}

#upload-doc-section {
  display: none;
}

#upload-apidoc {
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  #upload-apidoc {
    font-size: 13px;
    text-align: center !important;
    margin-bottom: 20px;
  }
}

#rem-close-btn {
  position: absolute;
  color: red;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 50%;
  background: #eee;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
.mobile__pdf__container__header {
  display: none !important;
}

.padding_30 {
  padding: 30px !important;
}

.t-uppercase {
  text-transform: uppercase !important;
}

#cust-id-aggr {
  background: #f3f3f3;
  border-top: 2px solid black;
}

.bg-highlight {
  background: aliceblue !important;
}

.bold {
  font-weight: bold;
}
.cases_length {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #3dbc3d;
}

#align-hidden th {
  visibility: hidden;
}

#align-table {
  position: relative;
  top: -75px;
}

.deposit-table {
  border-radius: 0px !important;
  margin-bottom: 0px;
}

.deposit-table .form-control {
  padding: 5px;
}

.deposit-table .loan-table td,
.deposit-table .loan-table th {
  background-color: #fff;
  border: 1px solid #dee2e6 !important;
}

#red-id {
  color: red;
}

#green-id {
  color: green;
}

.case_width_table td {
  width: 8.5%;
}

.case_width_table th {
  width: 10%;
}

#ct-id {
  font-size: 19px;
}

.notice_dash_css {
  padding: 7px;
  border-bottom: 1px solid #edebeb;
  margin-bottom: 17px;
}

#dw-ap-cont {
  margin: 25px 0px;
  display: flex;
  justify-content: center;
}

#download-api-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 5px;
  cursor: pointer;
  color: #145388;
  font-size: 15px;
}

#download-api-btn i {
  color: #145388;
  font-size: 20px;
}

.auto-task-new td {
  width: 19% !important;
}

.auto-task-new .form-control {
  padding: 6px !important;
}

#circle-filled {
  margin-left: 2px;
  height: 12px;
  width: 12px;
  background-color: #78e355;
  border-radius: 50%;
  display: inline-block;
}

#circle-empty {
  margin-left: 2px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid #b8bcac;
  height: 12px;
  width: 12px;
}

.required-input-error {
  border: 1px solid red !important;
}

#h_iframe iframe {
  width: 100%;
  height: 90vh;
}
#h_iframe {
  height: 100%;
}

#edit_138_blocks div {
  margin-bottom: 15px;
}

#srch_type_sel {
  padding-right: 0px !important;
  /* padding-left: 0px !important;*/
  /*margin-left:20px !important;*/
}

#srch_type_sel select {
  /* background-color: white !important;*/
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  /*color:#02203c;*/
}

#srch-bar {
  padding-left: 0px !important;
}

#srch-bar .search-table {
  border-radius: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

#srch-bar .search-table input {
  padding: 7px 16px;
  border-left: 1px solid #e3e3e3;
}

#edit_138_blocks input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
#edit_138_blocks input[type="date"]::-webkit-clear-button {
  position: relative;
}

#edit_138_blocks input[type="date"]::-webkit-datetime-edit-year-field {
  position: absolute !important;
  background: white;
  /*border-left:1px solid #8c8c8c;*/
  padding: 2px;
  /*color:#000;*/
  left: 0px;
}

#edit_138_blocks input[type="date"]::-webkit-datetime-edit-month-field {
  position: absolute !important;
  border-left: 1px solid grey;
  padding: 2px;

  left: 37px;
}

#edit_138_blocks input[type="date"]::-webkit-datetime-edit-day-field {
  position: absolute !important;
  border-left: 1px solid grey;
  padding: 2px;
  left: 59px;
}

#reminder-filter-btn {
  background: #145388 !important;
  color: white !important;
  font-weight: bold;
  position: relative;
}

#reminder-filter-btn span {
  position: absolute;
  top: -2px;
  right: -2px;
  background: red;
  color: red;
  font-size: 8px;
  padding: 0.5px 4px;
  border-radius: 10px;
}

#selectapptype {
  text-transform: capitalize;
  float: right;
  padding: 20px 10px;
  padding-right: 0px;
  padding-bottom: 0px;
}

#caf input[type="number"]::-webkit-inner-spin-button,
#caf input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#payment-link-btn {
  position: absolute;
  left: 20px;
  bottom: 35px;
}

#create-new-temp-btn {
  position: absolute;
  left: 20px;
  bottom: 10px;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  #prev-btn-caselink {
    left: 17% !important;
  }
}

#prev-btn-caselink {
  z-index: 20;
}

/*#table-caselink-w td {
  overflow-wrap: break-word;
 max-width: 50px !important;
 overflow: hidden;*/

/*}*/
/*#table-caselink-w #pencil-edit{
  max-width: 10px !important;
}

#table-caselink-w th {
   max-width: 50px !important;
}

#tabl-caselink-w thead{
  width:100%;
}
*/
#cslink-tab-align {
  table-layout: fixed;
}
#cslink-tab-align td {
  width: 20%;
}
#cslink-tab-align td i {
  position: absolute;
  /*position absolute of pencil icon becomes important when the caslink table has high number of columns so that
  it won't take up next line due to its fixed width*/
}

#text-create-email {
  height: 100px;
}

.case-tracking-modal-button {
  /* background: #2a93d5; */
  border: 8px;
  color: white;
  width: 40%;
  cursor: pointer;
  padding: 2px;
}

.color-black {
  color: black;
}

.mini-scrollbar-parent div::-webkit-scrollbar {
  height: 5px;
}

.case-tracking-reminder .add-stage-btn {
  border-radius: 8px;
  padding: 4px;
  background-color: white;
}

.b-radius-4 {
  border-radius: 4px;
}

#allo_radio {
  padding-top: 15px;
}

#allo_radio div {
  margin-left: 10px;
}

#hidden-table {
  display: none;
}

#hidden-table-fos {
  display: none;
}

#create-cl-btn {
  right: 8%;
  position: absolute;
}

#ct-style {
  color: black !important;
  padding: 1px;
  border: 3px solid lightgreen;
  border-radius: 100%;
  background: lightgreen;
}

#call-modal-number-field {
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

#brbd {
  word-wrap: break-word !important;
  overflow-wrap: break-word;
  width: 400px !important;
}

.case-stage-pop-up {
  max-height: 80vh;
  min-height: 80vh;
  overflow-y: scroll;
}
.case-stage-pop-up::-webkit-scrollbar {
  width: 5px;
}
.view-stage-pop-up-items::-webkit-scrollbar {
  width: 5px;
}
.view-stage-pop-up-items::-webkit-scrollbar-track {
  border-radius: 30px;
  background-color: whitesmoke;
}
.view-stage-pop-up-items::-webkit-scrollbar-thumb,
.case-stage-pop-up::-webkit-scrollbar-thumb,
.case-stage-pop-up-body::-webkit-scrollbar-thumb {
  background: rgb(191, 191, 192);
}

.case-stage-pop-up-body {
  max-height: 90vh;
  min-height: 90vh;
}
.case-stage-pop-up-body::-webkit-scrollbar {
  width: 5px;
}

.create-case-stage-body {
  max-height: 700px;
  height: 100%;

  overflow-y: scroll;
}
.create-stage-body-row {
  max-height: 65vh !important;
}
.case-tracking-createSatge-document-upload {
  height: 65vh !important;
}

.view-case-stage-left-item-heading {
  overflow-wrap: anywhere;
}

.pop-up-box {
  margin-left: 10px;
}

#doc_listing {
  margin-bottom: 10px;
  padding-top: 10px;
  position: relative;
  /*border:2px solid lightblue;
  border-top: 5px solid lightblue;*/
  text-align: left;
  box-shadow: 0 0 5px #145388;
  background: #f6f9fc;
}

#dl_name span {
  color: #145388;
  font-weight: bold;
}

#dl_remove {
  position: absolute;
  right: 3px;
  top: -10px;
  color: red;
  cursor: pointer;
}

#tr-fields {
  padding-top: 3px;
  padding-bottom: 5px;
}

.container_file_upload {
  text-align: left;
}

#sel-count-files {
  font-size: 15px;
  font-weight: bold;
  /*position: relative;
  top:20px;*/
}

#sel-count-files button {
  border: none;
  font-weight: normal;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 15px;
  background: #145388;
  color: white;
  margin-right: 10px;
  margin-left: 0px !important;
}

#sel-count-files #cls-btn {
  font-weight: normal;
  color: white;
  background: red;
  margin-left: 10px;
}

#sel-count-files #upld-btn {
  position: absolute !important;
  right: 0px;
}

#file-upload {
  /* width:12%;
  /*z-index: 1000;*/
  /*cursor: pointer;
  position: absolute;
  top:0px;
  opacity: 0;*/
  display: none;
}

#err-page-logout {
  color: blue;
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

#preview-btn {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

#cpy-det-doc label {
  color: #145388;
  padding: 0px 0px 5px 0px;
}

#agent-allocation-scroll {
  max-height: 260px;
  overflow-y: auto;
}

/*#fixed-header{
  position: fixed;
}*/

#err-msg-doc {
  height: 10px;
  /*font-size:200px;*/
}

#doc-access-denied {
  font-size: 15px;
  font-weight: bold;
  color: #145388;
  margin-left: 20px;
  margin-top: 20px;
  /*margin-left:10px;*/
}

.custom-modal-style {
  width: 2000px;
  height: 600px;
}

.td-font {
  font-size: 0.85rem;
}

.speedpost-iframe-modal {
  height: 100vh !important;
}

.speedpost-iframe-modal .speedpost-iframe-parent {
  height: 100%;
}

.speedpost-iframe-parent .speedpost-iframe {
  width: 100%;
  height: 90vh;
}

/*#call-connected-loading{
  position: absolute;
  left:50%;
}*/

.view-stage-pop-up-items {
  padding: 1rem 0;
  overflow-y: auto;
}
.view-stage-pop-up-items.document-box {
  max-height: 300px;
}
.view-stage-pop-up-items.notice-box {
  max-height: 200px;
}
.view-stage-pop-up-items.allocation-box {
  max-height: 200px;
}

.send-email-modal-heading {
  word-break: break-all;
}

.case-stage-document-upload-action-buttons {
  flex-basis: 20%;
}

.case-tracking-divider {
  border-top: 1px solid #aba6a6e6;
}
.case-tracking-download-icon {
  border-radius: 50em !important;
  opacity: 0;
}

.case-tracking-caseSec-tr:hover .case-tracking-download-icon {
  border: 1px solid rgba(97, 92, 92, 0.247) !important;

  opacity: 1;
}

.case-tracking-download-icon:hover {
  color: #3493d5 !important;
  font-size: 1.25rem !important;
  background-color: rgba(128, 128, 128, 0.219) !important;
  border-radius: 5px !important;
}

/* .case-tracking-download-icon:hover {
  border: 1px solid rgba(97, 92, 92, 0.247) !important;

  color: black !important;
  border-radius: 5px !important;
  font-size: 1.25rem !important;
} */

.create-case-stage-data-field {
  display: block;
  white-space: nowrap;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#year-month-picker {
  max-width: 190px;
  width: 200px !important;
  position: absolute;
  background: #f8f8f8;
  z-index: 99999;
  left: -32px;
  top: 50px;
  animation: dropallc 0.3s;
}

@keyframes dropallc {
  from {
    top: 0px;
  }
  to {
    top: 50px;
  }
}

.case-tracking-create-document-collapse {
  /* background: #e0f1ff94; */
  box-shadow: 0 4px 11px #dee9fc, 0 5px 13px rgb(0 0 0 / 4%);
  border-radius: 8px;
  min-width: 60rem;
  border: 1px solid grey;
}

.case-tracking-create-document-collapse-red {
  /* background: #e0f1ff94; */
  box-shadow: 0 4px 11px #dee9fc, 0 5px 13px rgb(0 0 0 / 4%);
  border-radius: 8px;
  min-width: 60rem;
  border: 2px solid red;
}

.case-tracking-create-notice-view {
  background: whitesmoke; /* background: rgb(182, 226, 255); */
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  /* padding: 5px; */
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 5px;
  box-shadow: 0 2px 4px rgb(230, 230, 230);
}

.case-tracking-create-document-collapse-button {
  border-radius: 15px !important;

  border: none !important;
  background-color: transparent !important;
}

.case-tracking-create-document-collapse-button:hover {
  border-radius: 15px !important;

  box-shadow: 0px 0px 10px 0px #6a8edb !important;
  border: none !important;
}

.case-tracking-create-flexbox {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.document-upload-input {
  border: 1px solid #dedede;
  border-radius: 3px;
  /* width: 49vw; */

  padding: 0.5rem 0.45rem;
}

.document-upload-file-input {
  position: relative;
  text-align: right;
  opacity: 0.5;
  z-index: 2;
}
.document-upload-heading {
  color: #66869c;
}

.case-tracking-view-document {
  /* position: relative; */
  background: #e0f1ff94;
  box-shadow: 0 4px 11px #dee9fc, 0 5px 13px rgb(0 0 0 / 4%);
  border-radius: 8px;
  min-width: 90%;
  height: fit-content;
}

.case-tracking-view-document-title {
  color: grey;
}

#allocation_dropdown .dropdown-menu {
  padding: 0px !important;
  background: #f8f8f8;
}

#allocation_dropdown .dropdown-item,
#allocation_dropdown .dropdown-item i {
  font-weight: 600;
  cursor: pointer;
}

#allocation_dropdown .dropdown-divider {
  margin: 0px;
}

#ymp-dropdown-item:active {
  background-color: #bfbfbf !important;
}

#ymp-container {
  position: relative;
}

#tag-ip .react-tagsinput-tag {
  text-transform: capitalize;
}

#notice-dashboard-download-table {
  font-size: 14px;
}

#notice-dashboard-download-table thead {
  font-weight: bold;
  background: #eee;
}

.border-red {
  border: 1px solid red !important;
}
.required-message-warning {
  color: red !important;
}

.edit-history-modal {
  max-height: 60vh;
  overflow-y: scroll;
  height: 100%;
}
.case_tracking_edit_history_header {
  padding: 0.75rem 1rem !important;
  display: flex;
  flex-direction: row;
}
.case_tracking_edit_history_fotter {
  padding: 0.85rem 1.25rem !important;
}
.case-tracking-status-icons {
  width: 20px;
  height: 20px;
  font-size: 1.25rem;
  padding: 0 1rem;
  opacity: 0;
}

.case-tracking-cases .case-tracking-status-icons {
  opacity: 1;
  cursor: pointer;
}

.case-tracking-view_edit-history-icon {
  width: 20px;
  height: 20px;
  font-size: 1.25rem;
  padding: 0 1rem;
  opacity: 1;
}

.case-tracking-caseSec-tr:hover .case-tracking-status-icons {
  opacity: 1;
  cursor: pointer;
}

.case-tracking-status-icons:hover {
  color: #3493d5;
}
.case-tracking-cases-position-stickey {
  position: sticky !important;
  top: 65px !important;
  display: flex !important;
}

.case-tracking-cases-icon-divider {
  border-left: 2px solid #8d8d8d2e;
  min-height: 30px !important;
  padding: 6px 0;
  margin: 0 20px;
}

#download-btn-ws {
  cursor: pointer;
  margin-top: 8px;
}
#download-btn-ws i {
  /* padding: 8px; */
  border: 2px solid #d8d8d8;
  background: white;
}

.payment-link-grid {
  margin-top: 2px;
  border: 1px solid #d0caca;
  padding: 5px 10px;
  font-size: 15px;
}

.dashLoader {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 40%;
}

.dashboard-table-switch {
  display: inline;
  /*position: absolute;
  margin-left:10px !important;*/
}
.dashboard-table-switch .react-switch-bg {
  /*height:17.5px !important;
  width:45px !important;*/
}

/*.dashboard-table-switch .react-switch-bg div{
  height:22px !important;
  width:15px !important;
}*/

.dashboard-table-switch .react-switch-handle {
  /*height:15px !important;
  width:15px !important;*/
}

.filterLoader {
  width: 50%;
  position: center;
  position: relative;
  left: -90px;
  top: -150px;
}

.create-stage-edit-history-download-icon {
  font-size: 1.5rem;
  font-weight: 700;
  float: right !important;
  margin-right: 1.25rem;
  cursor: pointer;
}

.create-stage-view-download-icon-pencil {
  font-size: 1.5rem;
  /* font-weight: 700; */
  cursor: pointer;
  margin-left: 1.5rem;
}

.create-stage-view-download-icon-clock {
  font-size: 1.5rem;
  /* font-weight: 700; */
  cursor: pointer;
}

.create-case-document {
  width: 50vw !important;
  margin: 0 auto;
}
.case_tracking_edit_history_header h5 {
  width: 100% !important;
  display: block;
}
.case_tracking_edit_history_header-div {
  width: 100% !important;
  display: flex;
}

.dashv2-table-container {
  padding: 20px 2px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px rgba(40, 41, 61, 0.04), 0px 4px 10px rgba(96, 97, 112, 0.16);
}

.dashv2-table thead tr th {
  padding: 10px 0px !important;
}

.dashv2-table th {
  border-top: 1px solid black !important;
  background-clip: padding-box;
}

.dashboard-v2-table h3 {
  padding: 10px 20px !important;
}

.dashboard-v2-table-index {
  font-size: 14px;
  word-spacing: 2px;
}

.dashboard_v2_dashboards {
  height: 40px;
}

.dashboard_v2_dashboards:first-child {
  margin-top: 3px !important;
}

.d_v2_bar_container div div div {
  zoom: calc(1 / 0.9);
}

.d_v2_bar_container div div svg > g {
  transform: translate(100px, 50px);
}

.dashboard_v2_tabs_container {
  display: inline-block;
  margin-left: 10px;
  border-bottom: 0px !important;
}

.filter-v2-datepicker {
  max-width: 100%;
  padding: 5px 0px;
  margin: 1px 0px;
}

.filter-v2-datepicker input {
  font-weight: bold;
}

.filter-v2-input {
  position: center;
  max-width: 95%;
  padding: 5px 0px;
  margin-left: auto;
  margin-right: auto;
}

.filter-v2-input input {
  border: 0.2px solid #f2f2f5 !important;
  background: #f2f2f5 !important;
}

.table-filter-collapse .table-responsive {
  overflow: visible;
}

/*.dashboard-v2-table, .dashboard-v2-graph{
  box-shadow: 0px 2px 3px 1px rgba(28, 50, 79, 0.38);
}*/

.dashboard-v2-graph {
  border-radius: 20px !important;
  box-shadow: 0px 0px 4px rgba(40, 41, 61, 0.04), 0px 4px 10px rgba(96, 97, 112, 0.16);
}

.dashboard-v2-graph h3 {
  color: #28293d;
  padding: 10px 20px !important;
  padding-top: 20px !important;
}

.dashboard-v2-tab {
  text-transform: capitalize;
  font-size: 13px !important;
  cursor: pointer;
}

.dash-am-container {
  border: 1px solid #3e7bfa;
  border-radius: 10px;
  padding-left: 0px;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
}

/*.dash-allocationPicker{
  height: 10px !important;
}*/

.dash-allocationPicker #dropdown_toggle {
  max-width: 95px;
  border-radius: 10px !important;
  padding-top: 10px;
  color: #3e7bfa;
  font-weight: bold;
}

.dash-allocationPicker .dropdown-menu {
  margin-top: 20px;
  background: #ffffff !important;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  top: -10px !important;
}

.dash-allocationPicker #year-month-picker {
  background: #ffffff !important;
}

.dv2-filter-btn {
  /* border: 1px solid #3e7bfa;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
    border-radius: 10px; */
}

.dv2-filter-btn div {
  position: relative;
  /* margin-top: 10px; */
}

.dv2-filter-btn .filter-by {
  border: 1px solid #3e7bfa;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
}

.filter-arrow-right {
  color: #3e7bfa;
  font-size: 13.5px;
  font-weight: bold;
  margin-bottom: 10px;
}

.filter-count {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  position: absolute;
  top: -7px;
  left: 20px;
  z-index: 200;
  cursor: default;
  color: #ffffff !important;
  background: #3e7bfa !important;
}

.dash-v2-nav {
  padding: 8px 0px !important;
  border-radius: 15px;
}

.dashboard-v2-graph text {
  font-size: 13.5px !important;
  font-family: Poppins, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-transform: capitalize;
}

.btnp-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.pagination-no-middle {
  cursor: pointer;
  font-size: 14px;
  margin: 0px 10px;
  color: #3e7bfa;
  background: #ccddff;
  border-radius: 50%;
  display: inline-block;
  width: 24.5px;
  height: 24px;
  line-height: 24px;
}

.case-tracking-loan-details-card-row > * {
  margin-top: 10px;
  width: 30% !important;
  flex-grow: 1 !important;
}

/* .case-tracking-stage-section {
  border-bottom: 3px solid #0000003b;
} */

.case-tracking-stage-row.pending {
  border-left: 4px solid #afeaf0;
}

.case-tracking-stage-row.in_progress {
  border-left: 4px solid #fdac42;
}

.case-tracking-stage-row.under_approval {
  border-left: 4px solid #4d0099;
}
.case-tracking-stage-row.approval_request_rejected {
  border-left: 4px solid red;
}

.case-tracking-stage-row.done {
  border-left: 3px solid #0aff0a;
}
.case-tracking-view-stage-StageName {
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.case-tracking-create-stage-StageName {
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.create-stage-view-download-icon-div {
  display: flex;
  position: absolute;
  right: 80px;
}
.custome-theme {
  padding: 0.01rem auto !important;
  border-radius: 55px !important;
}
.litigation-reminder-count {
  border: 0.001px solid #3e7bfa;
  background-color: whitesmoke;
  color: #3e7bfa;
  border-radius: 50%;
  padding: 0.1rem 0.5rem;
  margin-left: 0.5rem;
  font-size: 0.95rem;
  font-weight: 600;
  display: inline-block;
}

.case-tracking-special-stage {
  background-image: linear-gradient(360deg, #8f8b8b14, #c1e4ff12);
}

.litigation-dashboard-step-heading {
  font-size: 1.4rem;
}
.litigation-dashboard-step-card-body {
  border: 0.5px solid #e0e0e0;
}
.litigation-dashboard-step-card-body .litigation-dashboard-step-card-count-box {
  border-top: 0.4px solid #e0e0e0;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.custom-ai-modal {
  box-shadow: none !important;
}
.custom-ai-modal .modal-content {
  border-radius: 15px !important;
  box-shadow: 0 3px 30px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%);
}

.custom-ai-modal .modal-body {
  border: none !important;
}

.custom-ai-modal .modal-body .row {
  font-size: 14px;
}

.ai-weekday {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  font-weight: 400;
  margin-left: 15px;
  border-radius: 50%;
  text-align: center;
  background: #f2f2f5;
  color: #8f90a6;
  cursor: pointer;
}

.ai-weekday-active {
  background: #0063f7;
  color: #ffffff;
  width: 26px;
  height: 26px;
  line-height: 26px;
}

.ai-custom-sec-1 h6 {
  padding-top: 10px;
}

.ai-custom-sec-1 .form-control {
  background: #f2f2f5;
}

.ai-custom-sec-3 input {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: inline-block;
  background: #f2f2f5 !important;
}

.ai-custom-sec-3 .react-datepicker-wrapper input {
  background: #f2f2f5 !important;
}
.ai-custom-sec-3 .custom-radio {
  margin-top: 8px;
}

.ai-rule-container {
  border: 1px solid #145388;
  border-radius: 5px;
}

/*.ai-rule-container .auto-task-new{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}*/
.create-process-step-input-form input[type="number"] {
  -moz-appearance: textfield;
}
.create-process-step-input-form input[type="number"]::-webkit-inner-spin-button,
.create-process-step-input-form input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#send-comm-grid {
  grid-template-columns: 50% 50%;
}

.comm-modal-header .modal-title {
  width: 100%;
}

.disable-spin[type="number"]::-webkit-inner-spin-button,
.disable-spin[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dashboard_v2_skeleton .skeleton {
  height: auto !important;
  overflow: hidden;
}

.dashboard_v2_skeleton .skeleton--content {
  height: auto !important;
}

.case-tracking-hold-case-top-row {
  background-color: rgb(170, 223, 255);
}

.case-tracking-fotter-row {
  display: flex;
  justify-content: space-evenly;
}
.case-tracking-fotter-link:hover {
  text-decoration: underline;
}

.filter-checkbox-list-height {
  overflow-y: auto;
  height: auto;
}

.stop-uploading-btn {
  margin-top: 10px;
  align-items: center;
  display: flex;
}

.stop-uploading-btn button {
  margin: 0 auto;
}

.ai-var-remove-icon {
  position: absolute;
  color: red;
  font-weight: bold;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.styled__control-is-focused:hover {
  border-color: none;
}
.styled__control-is-focused:focus {
  border-color: none;
}
.disabled-class {
  background-color: grey !important;
}

.export-filter-count {
  top: -7px;
}
.portfolio-pop-up-card-drop-down .card {
  box-shadow: none !important;
  border-radius: none !important;
  border: none !important;
}
.portfolio-pop-up-card-drop-down .card .card-body {
  padding: 1.6rem 2rem 0.4rem 2rem !important;
}

/* For New Portfolio design */
.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
}

.sub-filter-wrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.new-month .year-dropdown,
.new-sortBy .year-dropdown {
  background: #ffffff;
  border: 1px solid #e4e4eb;
  border-radius: 9px;
  color: #28293d;
  font-size: 12px;
}

.new-month .focused,
.new-sortBy .year-dropdown:focus {
  border: 1px solid #3e7bfa;
  box-shadow: 0px 0px 2px rgb(40 41 61 / 4%), 0px 4px 8px rgb(96 97 112 / 16%);
}

.new-month .year-dropdown button {
  color: #28293d;
  font-size: 12px;
  font-weight: normal;
}

.new-month .year-dropdown button::after {
  content: "";
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("./blue-che.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 0px;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;
  transform: translate(13px, -14px);
  margin-left: 0em;
  vertical-align: 0em;
  border: none;
}

.new-sortBy .year-dropdown {
  padding: 10px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("./blue-che.svg");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 16px;
  cursor: pointer;
  width: -webkit-fill-available;
  height: 2.6rem;
}

.search-input {
  display: grid;
  border-radius: 39px 0px 0px 39px;
  width: auto;
  border: 1px solid #e4e4eb;
  grid-template-columns: 1.3fr 1.3fr;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
}

/* @media (min-width: 1300px){
  .search-input{
    max-width: 30%;
  }
} */

.search-input input {
  border: none;
  outline: none;
}

.search-input .new-sortBy select {
  border-radius: 9px 30px 30px 9px;
  height: 2.5rem;
  border: none;
  border-left: 1px solid #e4e4eb;
}

.filter-by,
.new-download {
  width: 40px;
  min-height: 2.25rem;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e4e4eb;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  background-image: url("../icons/filter.svg");
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: center;
}

.new-download {
  background-image: url("../icons/download.svg");
}

.new-download:focus {
  border: 1px solid #3e7bfa;
  box-shadow: 0px 0px 2px rgb(40 41 61 / 4%), 0px 4px 8px rgb(96 97 112 / 16%);
}

.select-loans {
  width: 120px;
  height: inherit;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  border: 1px solid #e4e4eb;
  border-radius: 8px;
  font-size: 12px;
  color: #28293d;
  background-color: transparent;
}

.auto-dial-button {
  width: 160px;
  height: 2.5rem;
  filter: drop-shadow(0px 6px 10px rgba(62, 123, 250, 0.3));
  font-size: 12px;
  color: #fff;
  outline: none;
  border: none;
  background: #3e7bfa;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.approval-request-page-collapse-details {
  border: 3px solid rgba(69, 129, 248, 0.418);
  padding: 0.5rem 0.5rem;
  border-radius: 15px;
  box-shadow: 0px 0px 7px 0px rgba(69, 129, 248, 0.247);
}

.tabel-td-border-none {
  border: none !important;
}
.approval-select-drop-down {
  display: block;
  width: 100%;
  padding: 13px;
}

.focusedInput,
.focusedFilter,
.select-loans:focus {
  border: 1px solid #3e7bfa;
  box-shadow: 0px 0px 2px rgb(40 41 61 / 4%), 0px 4px 8px rgb(96 97 112 / 16%);
}

.word-break-all {
  word-break: break-all;
}

.font-weight-500 {
  font-weight: 500;
}

.inc__button {
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  color: #3e7bfa;
  font-size: 16px;
  border: none;
  outline: none;
}

.inc__button:disabled {
  pointer-events: none;
}

.button-grid {
  display: grid;
  grid-template-columns: 40px 120px 40px;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.ai-allocation-select-group {
  display: flex;
}

.orange {
  color: orange !important;
}

.did_management_table .disabled {
  pointer-events: none;
}

.list_type_row {
  background: #f2f2ff;
  border: 1.2px solid #b4b4b4;
}

.list_in_list_type_row {
  background: #f2f2ff;
  border-right: 1.2px solid #b4b4b4;
  border-left: 1.2px solid #b4b4b4;
}

.list_in_list_type_row.last {
  border-bottom: 1.2px solid #b4b4b4;
}

.disable-resize {
  resize: none;
}

.dialer_message {
  text-align: center;
  display: block;
  margin-top: 11vh;
}

.call_icons .blockContent .phone_active {
  background: #dadada;
}

#start_pause_button.blockContent {
  color: gray;
}

.spin-disabled-container input[type="number"]::-webkit-inner-spin-button,
.spin-disabled-container input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remark-textarea {
  width: 100%;
  height: 100px !important;
  resize: none;
}

.dv2-table-download-btn i {
  padding: 4px;
  background: linear-gradient(147.14deg, #5b8def 6.95%, #5b8def 6.96%, #0063f7 93.05%);
  color: white;
  border: 1px solid #ffffff;
  border-radius: 20%;
  font-weight: bold !important;
  cursor: pointer;
}

.dv2-table-download-btn i::before {
  font-weight: 600 !important;
}

.dv2-rangeContainer .dropdown-menu {
  transform: none !important;
  top: 100% !important;
  left: -100% !important;
}

.dv2-rangeContainer .rdrInputRanges {
  display: none !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.autoMaxWidth #dropdown_toggle {
  max-width: none;
}

.input-with-eye {
  color: red;
  position: relative;
}

.input-with-eye input {
  padding-right: 30px !important;
}

.input-with-eye i {
  color: #145388;
  position: absolute;
  right: 20px;
  top: 35%;
  font-size: 16px;
  cursor: pointer;
}

.my-modal {
  width: 80vw; /* Occupy the 90% of the screen width */
  max-width: 80vw;
  border-radius: 10px !important;
}

.modal-content-custom-radius {
  border-radius: 10px !important;
}
.custom-modal {
  border-radius: 10px !important;
}
.custom-modal-dialog {
  border-radius: 10px !important;
}
.custome-linked-loan-table {
  height: 559px;
  overflow-y: auto;
  overflow-x: hidden;
}

.d-grid::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}
.d-tags-grid {
  max-height: 150px;
  overflow: auto;
  height: auto;
}
.d-tags-grid::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.custome-linked-loan-table::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}
.cg-primary-button-bg {
  background-color: #6698fa !important;
  border: none;
  border-radius: 4px !important;
}
.cg-primary-button-bg:hover {
  background-color: #3f7bfa !important;
}
.cg-primary-button-bg-main {
  background-color: #3f7bfa !important;
  border: none;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}
.notice-width-animation {
  animation-name: notice-pop-up-width-fade;
  animation-duration: 0.6s;
}
@keyframes notice-pop-up-width-fade {
  0% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}
.linked-loan-id-list {
  border: 1px solid #e6eaf6;
}
.linked-loan-id-list:hover {
  background-color: #f2f2f5;
}
.linked-loan-id-list-active {
  background-color: #4585fa !important;
  /* background: linear-gradient(147.14deg, #5B8DEF 6.95%, #5B8DEF 6.96%, #0063F7 93.05%); */
  border-radius: 10px !important;
}
.btn-new-cancel {
  border: none !important;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
}

.w25-lu td {
  width: 25% !important;
}

.w20-lu td {
  width: 20% !important;
}

.portfolio-search-btn {
  border-radius: 0px 39px 39px 0px !important;
  margin-left: -16px;
  height: 40px;
}

.font-10 {
  font-size: 10px;
}

.fos-dropdown {
  background: #fafafc;
  border-radius: 9px;
  width: 11rem;
  height: 2.25rem;
  border: none;
  color: #555770;
  cursor: pointer;
  padding: 0.5rem;
  outline: none;
  box-shadow: 0px 0px 1px rgb(57 217 138 / 4%), 0px 2px 4px rgb(57 217 138 / 16%);
}

.check {
  padding-top: 0rem;
}

.check::before {
  top: 41px !important;
  background-color: #3e7bfa !important;
}

.red-text {
  color: red;
  font-weight: bold;
  text-decoration: underline;
  margin: 0;
}

.green-text {
  color: green;
  font-weight: bold;
  text-decoration: underline;
  margin: 0;
}

.branch-table {
  border-bottom: 2px solid #bfbfbf;
}

.balloon-btn {
  /* padding:3px 10px; */
  border-radius: 5px;
  border: 1px solid #f2f2f5;
  margin: 5px;
  color: #0063f7;
}

.status-multiselect input {
  width: 100%;
}

.edit-status-enabled,
.edit-status-disabled {
  margin: 4px;
  background-color: white;
  border: 2.2px solid #bfbfbf;
  color: #0063f7;
  font-weight: 600;
}
.edit-status-disabled {
  color: #9a9a9a;
}

.modal-rounded .modal-dialog,
.modal-rounded .modal-content {
  margin-top: 100px !important;
  height: -webkit-fill-available !important;
  border-top-left-radius: 12px !important;
  max-width: 550px !important;
}

.modal-rounded .modal-title {
  font-size: 1.5rem;
}

.bank-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.key-value-pair {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
}

.value-box {
  width: 290px;
  height: fit-content;
  background: #fafafc;
  border: 1px solid #c7c9d9;
  border-radius: 4px;
  padding: 10px;
  color: #555770;
}

.c-text {
  cursor: text;
}

.custom-port-srch {
  font-size: 11px;
  color: #707070;
  /*color: #d7d7d7;*/
  height: 37px;
  bottom: 0px;
  line-height: 2.2rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0px 17px 17px 0px;
  padding-right: 6px;
}

.search-table .portfolio-multi-select {
  padding: 0px;
  background: #f2f2f2 !important;
  border: 0px !important;
  width: auto;
}

.remsel {
  display: grid;
  grid-template-columns: 85% 15%;
  background: white;
}

.noticeAllocationMonthContainer {
  position: relative;
  top: 0%;
}

.webhook-container {
  padding: 30px;
  font-size: 14px;
}

.webhook-header-container {
  font-size: 16px;
  padding-bottom: 30px;
}

.webhook-header-link {
  padding-top: 10px;
}

.webhook-form {
  padding: 30px 0px 40px 0px;
}

.webhook-checkbox-container {
  padding-top: 10px;
}

.webhook-bottom-btn {
  padding: 30px 5px;
}

.portfolio_last_column_header {
  z-index: 20 !important;
}

.hidden_input {
  -webkit-text-security: disc;
}

.reallocateMultiSelect .chip,
.reallocateMultiSelect .highlightOption,
.reallocateMultiSelect li:hover {
  background-color: #3e7bfa !important;
}

.reallocateMultiSelect .searchWrapper {
  border-radius: 9px !important;
}

.reallocate_fos .upload-button {
  width: 150px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  cursor: pointer;
  outline: none;

  height: 37px;
  background: #3e7bfa;
  color: #ffffff;
  border: none;
}

.process-tracking-active-nav-link {
  border-bottom: 3px solid #3e7bfa !important;
  color: #000 !important;
}
.process-tracking-inactive-nav-link {
  color: #8f90a6 !important;
}
.chip-button {
  padding: 0.2rem 1rem;
  background: #ffffff;
  box-sizing: border-box;
  /* box-shadow: 0px 6px 10px rgba(255, 248, 229, 0.3); */
  border-radius: 24px;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.chip-button-all {
  border: 1px solid #004fc4;
  color: #004fc4 !important;
}
.chip-button-complete {
  border: 1px solid #05a660;
  color: #05a660 !important;
}
.chip-button-inprogress {
  border: 1px solid #fdac42;
  color: #fdac42 !important;
}
.chip-button-under-approval {
  border: 1px solid #4d0099;
  color: #4d0099 !important;
}
.chip-button-rejected {
  border: 1px solid #e63535;
  color: #e63535 !important;
}
.chip-button-all-active {
  background-color: #004fc4;
  color: #fff !important;
}
.chip-button-complete-active {
  background-color: #05a660;
  color: #fff !important;
}
.chip-button-inprogress-active {
  background-color: #fdac42 !important;
  color: #fff !important;
}
.chip-button-under-approval-active {
  background-color: #4d0099 !important;
  color: #fff !important;
}
.chip-button-rejected-active {
  background-color: #e63535;
  color: #fff !important;
}
.pt-remark-bg {
  background-color: #3f7bfa !important;
  border: 1px solid #3f7bfa !important ;
}
.process-tracking-row {
  border-bottom: 0.5px solid #c7c9d971 !important;
}

.litigation-export-date-picker {
  padding: 0.25rem !important;
}

.litigation-export-table td {
  width: 19% !important;
}

.litigation-export-table .form-control {
  padding: 6px !important;
}

.bulk-case-upload-fill-excel-checkbox .custom-control-input {
  cursor: pointer;
}

.did-table-div .table-responsive {
  height: 65vh;
}

.autodial-title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}

.autodial-control-stopwatch {
  width: 73px;
  height: 96px;
  left: 94px;
  top: 61px;
  position: fixed;
  align-items: center;
}

.autodial-call-list-header {
  font-size: 16px;
  font-weight: 400;
  padding: 4px;
}

.autodial-control-center {
  margin: auto;
  width: 50%;
}

.autodial-control-center-heading {
  text-align: center;
  border: 2px solid #c7c9d9;
  padding: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.autodial-control-center-section {
  border-style: solid;
  border-color: #c7c9d9;
  border-width: 0px 2px 2px 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;
  padding: 10px;
}

.autodial-control-center-single {
  width: 130px;
  height: 60px;
  border-radius: 9px;
  color: white;
  background-color: #05a660;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: fixed;
  right: 0;
  margin: 10px 10px 0;
}

.autodial-control-center-single:active {
  background-color: #04854d;
}

.autodial-control-center-single--disabled {
  background-color: #50c190;
  cursor: default;
}

.autodial-control-center-single--disabled:active {
  background-color: #50c190;
}

.autodial-control-center-single__info {
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.autodial-control-center-single__info__timer {
  font-size: 18px;
  font-weight: 600;
}

.autodial-control-center-single__info__title {
  font-size: 15px;
}

.autodial-control-center-single__info__title--large,
.autodial-control-center-single__icon > i {
  font-size: 20px;
}

.autodial-call-list-block {
  margin-left: -28px;
  margin-top: 8px;
  margin-bottom: 8px;
  max-width: 17%;
  flex: 1;
  height: 80vh;
}

.autodial-call-list-selected {
  border-top: 1px solid #e4e4e6;
  border-left: 1px solid #e4e4e6;
  border-bottom: 1px solid #e4e4e6;
}
.autodial-call-list-0 {
  box-sizing: border-box;
  border: 0.3px solid #e4e4e6;
  border-radius: 4px 4px 0px 0px;
}

.autodial-call-list-n {
  border-left: 1px solid #e4e4e6;
  border-bottom: 1px solid #e4e4e6;
  border-right: 1px solid #e4e4e6;
}

.autodial-call-list-last {
  box-sizing: border-box;
  border: 0.3px solid #e4e4e6;
  border-radius: 0px 0px 4px 4px;
}

.autodial-call-list-single {
  border: 0;
  background-color: #fafafd;
}

.autodial-radius-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.autodial-radius-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.autodial-callbody {
  margin-top: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.autodial-call-type-selector {
  border-bottom: 1px solid #e4e4eb;
  margin-bottom: 40px;
  display: flex;
}

.autodial-call-type-title {
  font-size: 14px;
  padding: 10px;
  color: #8f90a6;
  cursor: pointer;
}

.autodial-call-type-title--selected {
  box-shadow: 0 4px 0 #3e7bfa;
  color: inherit;
}

.autodial-background {
  background-color: #e5f0ff;
}

.autodial-bg-body {
  background-color: #fafafd;
  padding: 4px;
}
.autodial-tab-indicator {
  border-radius: 4px;
  border-width: 10px;
  height: 50px;
  width: 5px;
  position: relative;
  left: 0;
}

.autodial-tab-bg-selected {
  background-color: #fafafd;
  color: #3568d4;
  z-index: 1;
}

.autodial-hr-dashed {
  height: 10px;
}

.autodial-callbody-details-footer {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #f2f2f5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 0;
  display: flex;
  justify-content: space-between;
}

.autodial-callbody-details-footer__payment {
  display: flex;
}

.autodial-callbody-details-footer__payment__title {
  min-width: 165px;
  font-size: 14px;
  color: #555770;
  font-weight: 600;
}

.autodial-callbody-details-footer > a {
  font-size: 14px;
}

.autodial-list-tab {
  font-size: 16px;
  font-stretch: expanded;
  font-variant-numeric: tabular-nums;
  color: black;
  padding-top: 8px;
  padding-bottom: 8px;
}

.autodial-size {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.autodial-loading {
  position: fixed;
  height: 90vh;
  width: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.autodial-field-parent {
  flex-grow: 2;
  height: 297px;
  display: grid;
  grid-template-columns: auto auto auto;
  align-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  border: 1px solid #f2f2f5;
}

.autodial-field-child {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.autodial-field-child:nth-child(6n + 1),
.autodial-field-child:nth-child(6n + 2),
.autodial-field-child:nth-child(6n + 3) {
  background-color: #fafafd;
}

.autodial-field-value {
  min-width: 110px;
  max-width: 150px;
  color: #555770;
  word-break: break-all;
}

.autodial-form-container {
  border: 1px solid #f2f2f5;
  border-radius: 4px;
  padding: 15px 40px 75px 10px;
}

.autodial-form-head {
  align-items: center;
  margin-bottom: 30px;
}

.autodial-form-heading {
  font-size: 22px;
  font-weight: 600;
  margin-left: 5px;
}

.autodial-form-parent {
  flex: inherit;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: auto auto;
  grid-auto-flow: row;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.autodial-form-child {
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.autodial-form-child select,
.autodial-form-child select:focus {
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjMwNjQgMC45NjY0OTJMNS40NzgwNyA1LjA5NzczTDkuNjQ5NzUgMC45NjY0OTIiIHN0cm9rZT0iIzNFN0JGQSIgc3Ryb2tlLXdpZHRoPSIxLjAwMTAzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
}

.autodial-form-child select.inactive {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjMwNjE1IDAuOTY2NDkyTDUuNDc3ODMgNS4wOTc3M0w5LjY0OTUgMC45NjY0OTIiIHN0cm9rZT0iI0M3QzlEOSIgc3Ryb2tlLXdpZHRoPSIxLjAwMTAzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}

.autodial-form-key--even {
  width: 200px;
}

.autodial-form-value {
  width: 196.07px;
  height: 43.38px;
  border-radius: 4.91996px;
}

.autodial-disposition-dropdown--disabled {
  color: #ccc !important;
  background-color: #f7f7f7 !important;
}

.autodial-form-submit-btn {
  display: flex;
  justify-content: flex-end;
}

.autodial-submit-button {
  width: 180px;
  height: 50px;
  font-size: 18px !important;
  position: absolute;
  right: 80px;
}

.autodial-control-button {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: #3568d4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-column-center {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
}

.flex-column-start {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-row-center {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
}
.flex-row-space-between {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
}
.flex-row-space-evenly {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-evenly;
}
.cg-secondary-button {
  border: 1px solid #3f7bfa;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffffff !important;
  color: #3f7bfa !important;
  font-weight: 500;
  font-size: 14px !important;
  padding: 4px 35px !important;
}
.case-upload-button {
  padding: 4px 40px !important;
  background-color: #3f7bfa !important;
  border: none;
  border-radius: 4px !important;
  font-size: 15px !important;
}

.reallocate_new .parent-card {
  border-radius: 10px;
  border: 1px solid #e4e4eb;
  margin-bottom: 1rem;
}

.reallocate_new .header {
  min-height: 4rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 1rem;
}

.reallocate_new .card-body .excel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f2f2f5;
  border: 1px solid #c7c9d9;
  border-radius: 15px;
  padding: 21px 39px;
  gap: 0.5rem;
}

.reallocate_new .card-body .error-table {
  width: 100%;
  background: #fff;
  border: 1px solid #e4e4eb;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: 10px;
  height: fit-content;
  max-height: 200px;
  overflow: auto;
}

.reallocate_new .render-imp-points {
  font-size: 12px;
}

.reallocate_new .system-mapping-table {
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  background: #fff;
  overflow-y: auto;
}

.reallocate_new .system-mapping-table .table-header {
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 0.2fr 0.2fr;
  border-bottom: 0.5px solid rgb(143, 144, 166, 0.3);
  padding: 15px;
}

.reallocate_new .system-mapping-table .table-body {
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  padding: 15px;
  border-bottom: 0.5px solid rgb(143, 144, 166, 0.3);
  width: 100%;
}

.no-margin-paragraph {
  margin: 0em;
}

.upload-tracking-input {
  border: 1px solid #c7c9d9;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 31px;
  color: #3568d4 !important;
  cursor: pointer;
}

.upload-tracking-text {
  opacity: 0.8;
}
.upload-tracking-input:hover .upload-tracking-text {
  opacity: 1;
}
.upload-icon {
  font-size: 0.8rem;
  margin-left: 4rem;
  color: #3568d4 !important;
  opacity: 1 !important;
}
.download-notice-tracking {
  font-family: Poppins, Helvetica, Arial, Verdana, Tahoma, sans-serif;

  font-weight: 500;
  font-size: 1rem;
  line-height: 27px;
  text-decoration-line: underline;
  color: #3568d4;
}
.download-notice-tracking-active {
  opacity: 0.8;
}
.download-notice-tracking-active:hover {
  opacity: 1 !important;
  text-decoration-line: underline;
  color: #3568d4;
}
.download-notice-tracking-disable {
  opacity: 0.5;
  cursor: no-drop;
}
.tracking-files-uploaded {
  /* border: 2px solid #05A660; */
  box-shadow: 0 0 2px 2px rgba(70, 207, 115, 0.5);
}
.tracking-ids-count-text {
  font-size: 0.9rem;
}
.upload-tracking-button-disable {
  opacity: 0.5;
  cursor: no-drop;
}
.confirmation-modal-heading {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 45px;
  color: #28293d;
}
.confirmation-custom-modal {
  border-radius: 10px !important;
}
.confirmation-modal-text {
  font-weight: 500;
  font-size: 1rem;
  line-height: 27px;
  color: #555770;
}
.dispatch-type-text {
  font-weight: 400;
  font-size: 1.25rem;
  color: #555770;
}
.cg-secondary-button-with-white-bg {
  border: 1px solid #3568d4;
  color: #3568d4;
  font-weight: 500;
  background-color: #ffffff;

  border-radius: 4px;
}

.approval-page-row {
  border: 1px solid #e4e4eb;
  border-radius: 10px;
  background-color: white;
}

.approval-page-tabs {
  display: inline-block;
  border-bottom: 0px !important;
  height: 53px;
}

.approval-page-tab {
  font-size: 13px !important;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 1px !important;
}

.approval-page-filter-button-container {
  align-items: center;
}

.approval-page-filter-button {
  margin-left: 20px;
}

.approval-page-top-filters-button-selected {
  border-radius: 40px;
  background: transparent !important;
  border: 2px solid #3568d4 !important;
  color: #3568d4 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
}

.approval-page-top-filters-button-not-selected {
  border-radius: 40px;
  background: transparent !important;
  border: 2px solid #c7c9d9 !important;
  color: #c7c9d9 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
}

.approval-page-filter-by {
  display: inline;
  align-items: center;
  margin-top: 6px;
  font-size: 14px;
}

.approval-page-approve-button-disabled {
  background-color: #e4e4eb;
  border: none;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 156px;
  height: 43px;
}

.approval-page-approve-button-enabled {
  background-color: #469b76;
  border: none;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  min-width: 156px;
  height: 43px;
}

.approval-page-reject-button-disabled {
  background-color: #e4e4eb;
  border: none;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 156px;
  height: 43px;
}

.approval-page-reject-button-enabled {
  background-color: #c1443c;
  border: none;
  border-radius: 4px !important;
  font-size: 0.9rem !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  min-width: 156px;
  height: 43px;
}

.hierarchy-tooltip {
  border: 1px solid #d3d3d3 !important;
  color: #28293d !important;
  background-color: white !important;
}

.focus-outline:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.custom-medium-size-modal {
  width: 45vw;
  max-width: 45vw;
  border-radius: 20px !important;
}

.upload-draft-file {
  background: #f2f2f5;
  opacity: 0.8;
  box-sizing: border-box;
  border-radius: 4.91996px;
  font-weight: 500;
  font-size: 14px;
}

.notice-draft-upload-font-style {
  font-family: "Poppins", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  color: #28293d;
}

.batch-id-tag {
  background-color: #e5f0ff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-family: "Poppins", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}
.batch-id-aniamtion {
  animation: batch-id-right-tag 0.2s linear;
  transform: translateX(0px);
}
@keyframes batch-id-right-tag {
  from {
    transform: translateX(100px);
    display: none;
  }
  to {
    transform: translateX(0);
  }
}
.case_reminder {
  max-height: 75vh;
  overflow-y: scroll;
}

.rule_id_link {
  text-decoration: underline;
  color: #2584ff;
}

.create-stage-notice-draft-select {
  font-family: "Poppins", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #28293d;
}
.preview-eye-icon {
  font-size: 1rem;
  color: #3f7bfa;
}
.create-stage-draft-name {
  font-family: "Poppins", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #555770;
}

.create-stage-notice-check-box .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0052cc !important;
  border: 2px solid #0052cc;
  box-sizing: border-box;
  border-radius: 3px;
}
.preview-retry-icon {
  color: #3f7bfa;
}
.caseTracking-caseTabs {
  background: #f6f9fd;
  font-family: "Poppins", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #8f90a6;
}
.caseTracking-case-page-tab-active {
  border: none !important;
  background: #fff !important;
  color: #3568d4 !important;
}
.case-traking-case-details-tr {
  width: 100% !important;
  border: none !important;
}
.case-traking-case-details-document-tr {
  width: 100% !important;
  border: none !important;
}
.case-traking-case-details-document-tr > td:nth-child(4),
td:nth-child(5) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 20rem;
}
.case-traking-case-details-document-tr > td:nth-child(6) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
}
.case-traking-case-details-document-tr > td:nth-child(2),
.case-traking-case-details-document-tr > td:nth-child(3),
.case-traking-case-details-document-tr > td:nth-child(6) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 5rem;
}
.case-traking-case-details-document-tr > td {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.case-traking-case-details-tbody > .case-traking-case-details-tr:nth-of-type(odd) {
  background: #fafafd !important;
  border: none !important;
}

.casetracking-caseDocument-header > th {
  border: none !important;
  font-style: normal;
  font-weight: 600;
  color: #28293d;
  padding-right: 20px !important;
  padding-left: 20px !important;
  vertical-align: middle;
}
.credgenics-download-button {
  background: #004fc4 !important;
  border-radius: 4px !important;
  position: absolute;
  right: 50px;
  font-family: "Poppins", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px;
  padding: 0.35rem 1rem 0.35rem 1rem !important;
}
.credgenics-download-button-disable {
  background: #dfe1e6 !important;
  border-radius: 4px !important;
  border: none !important;
  position: absolute;
  right: 50px;
  font-family: "Poppins", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px;
  padding: 0.35rem 1rem 0.35rem 1rem !important;
  color: #a5adba !important;
  cursor: no-drop !important;
}
.workflow-internal-tool-error-table:nth-of-type(odd) {
  background: whitesmoke;
  border: none !important;
}
.side-bar-litigation-reminder-icon::before {
  content: url("../icons/litigation-reminder.svg");
  line-height: 60px !important;
}
.side-bar-litigation-approval-request-icon::before {
  content: url("../icons/approval-request.svg");
  line-height: 60px !important;
}
.side-bar-litigation-workflow-icon::before {
  content: url("../icons/litigation-workflow.svg");
  line-height: 60px !important;
}
.side-bar-litigation-internal-tool-icon::before {
  content: url("../icons/litigation-internal-tool.svg");
  line-height: 60px !important;
}

.side-bar-litigation-reminder-icon-active::before {
  content: url("../icons/litigation-reminder-active.svg");
  line-height: 60px !important;
}
.side-bar-litigation-approval-request-icon-active::before {
  content: url("../icons/approval-request-active.svg");
  line-height: 60px !important;
}
.side-bar-litigation-workflow-icon-active::before {
  content: url("../icons/litigation-workflow-active.svg");
  line-height: 60px !important;
}
.side-bar-litigation-internal-tool-icon-active::before {
  content: url("../icons/litigation-internal-tool-active.svg");
  line-height: 60px !important;
}
.side-bar-text {
  position: absolute;
  top: 1.2rem;
}

.emi-schedule-table {
  overflow-x: scroll;
}

.emi-schedule-table .table th {
  text-align: center !important;
}

.emi-schedule-table .main-header th {
  min-width: 120px;
  text-transform: capitalize;
}

.emi-schedule-table tbody .dark {
  background: #fafafc !important;
}

.emi-schedule-table .colspan-header th {
  border-left: 3px solid black;
  border-top: 2px solid black;
}
